import React from 'react';
import { RouteObject } from 'react-router-dom';

import {
    ChannelPage,
    CreatedPage,
    FavoritesPage,
    LastSentPage,
    PopularPage, SearchPage,
    StreamerPage,
    ThematicSetPage,
} from '@views';

export const StreamerAreaRoutes: Array<RouteObject> = [
    {
        path: '',
        element: <StreamerPage />,
    },
    {
        path: 'sent',
        element: <LastSentPage/>,
    },
    {
        path: 'favorites',
        element: <FavoritesPage/>,
    },
    {
        path: 'created',
        element: <CreatedPage/>,
    },
    {
        path: 'popular',
        element: <PopularPage/>,
    },
    {
        path: 'channel',
        element: <ChannelPage/>,
    },
    {
        path: 'thematic/:thematicSetId',
        element: <ThematicSetPage/>,
    },
    {
        path: 'search',
        element: <SearchPage/>,
    },
];
