import { IAuthTokensResponse } from '@typings';

import { baseApi } from './base.api';

/** Telegram API provides methods are user to login/register via Telegram */
export const telegramApi = baseApi
    .injectEndpoints({
        endpoints: (build) => ({
            /** Sends a request to register via Telegram (Common Bot) */
            telegramBotAuth: build.query<void, string | undefined>({
                query: (params?: string) => ({
                    url: 'telegram/auth?' + (params ?? ''),
                    method: 'GET',
                }),
            }),

            /** Sends a request to auth via Telegram (Common Bot) */
            webAppAuth: build.query<IAuthTokensResponse, object>({
                query: (params: object) => ({
                    url: 'telegram/web-app-auth?' + params,
                    method: 'GET',
                }),
            }),

            /** Sends a request to verify Telegram auth (Common Bot) */
            verifyAuth: build.query<{ valid: boolean }, object>({
                query: (params?: object) => ({
                    url: 'telegram/verify?' + params,
                    method: 'GET',
                }),
            }),
        }),
    });
