import { noop } from '@hyperclap/utils';
import cn from 'classnames';
import React from 'react';
// import { Virtuoso } from 'react-virtuoso';

import { ISticker } from '@typings';

import s from './MobileStickersGrid.scss';
import { MobileSticker } from '../MobileSticker';

interface StickersGridProps {
    className?: string,
    isAppleMobileDevice?: boolean,
    stickers: Array<ISticker>;
    stickersSoundEnabled?: boolean;
    columnsCount?: number;
    defaultSoundVolume?: number;
    onStickerClick?: (a: ISticker) => void;
    onSwitchSound?: () => void;
}
console.log('window.innerWidth', window.innerWidth - 18);

export const MobileStickersGrid = (props: StickersGridProps) => {
    const {
        className,
        stickers,
        onStickerClick = noop,
    } = props;

    const onStickerClickHandler = async (s: ISticker) => {
        onStickerClick(s);
    };

    // <Virtuoso
    //     className={cn(s.grid, className)}
    //     useWindowScroll
    //     totalCount={stickers.length}
    //     itemContent={(index) => {
    //         const sticker = stickers[index];

    //         return (<div className={s.sticker}style={{ height: calcHeight(sticker) }}>
    //             <MobileSticker
    //                 key={sticker.id}
    //                 sticker={sticker}
    //                 withSound={stickersSoundEnabled}
    //                 soundOnHover
    //                 loop
    //                 // useFadeInOnLoaded
    //                 useSkeletonOnLoading
    //                 useFallbackVideo={isAppleMobileDevice}
    //                 useAnimatedPreview
    //                 volume={sticker.customSettings?.customVolume || defaultSoundVolume}
    //                 sizeCalculationBase={SizeCalculationBase.WIDTH}
    //                 onClick={onStickerClickHandler}
    //             />
    //         </div>);
    //     }}
    // />
    return (
        <div className={cn(s.grid, className)}>
            {stickers.map((sticker) => (
                <MobileSticker
                    key={sticker.id}
                    sticker={sticker}
                    onClick={onStickerClickHandler}
                />
            ))}
        </div>
    );
};
