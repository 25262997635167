import {
    Column, ThemeContext,
    useModals,
} from '@hyperclap/ui';
import cn from 'classnames';
import React, { useContext, useEffect, useState } from 'react';

import { IconCloseLine } from '@assets/images/svg';
import { useCurrentUser, useVerification, useWebSocket } from '@hooks';
import { IUser, TWSMessage, VerificationStatus, WSMessageType } from '@typings';
import { VerifyState, SuccessState, FailState } from '@views/modals/UserVerificationPopup/components';

import s from './UserVerificationPopup.scss';

interface UserVerificationPopupProps {
    currentUser: IUser;
    isMobileLayoutRecommended?: boolean;
    reloadBalance?: () => void;
}

enum UserVerificationPopupState {
    VERIFY = 'VERIFY',
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
}

export const UserVerificationPopup = (props: UserVerificationPopupProps) => {
    const { closeModal } = useModals();
    const { theme } = useContext(ThemeContext);

    const {
        isMobileLayoutRecommended,
        reloadBalance,
    } = props;

    const {
        verificationUrl,
        loadVerificationUrl,
    } = useVerification();

    const { currentUser, refetchCurrentUser } = useCurrentUser();

    const {
        subscribe,
        unsubscribe,
    } = useWebSocket();

    const [popupState, setPopupState] = useState<UserVerificationPopupState>(UserVerificationPopupState.VERIFY);

    const onMessageHandler = (message: TWSMessage) => {
        const { type } = message;

        if (type === WSMessageType.VERIFICATION_STATE) {
            const status = message.data.status;

            switch (status) {
                case VerificationStatus.SUCCESS:
                    setPopupState(UserVerificationPopupState.SUCCESS);
                    refetchCurrentUser();
                    reloadBalance && reloadBalance();
                    break;
                case VerificationStatus.FAILED:
                    setPopupState(UserVerificationPopupState.FAILED);
                    void loadVerificationUrl();
                    break;
                case VerificationStatus.DELETED:
                    if (popupState !== UserVerificationPopupState.FAILED) {
                        setPopupState(UserVerificationPopupState.FAILED);
                    }

                    void loadVerificationUrl();
                    refetchCurrentUser();
                    reloadBalance && reloadBalance();
                    break;
            }
        }
    };

    useEffect(() => {
        if (currentUser) {
            subscribe(currentUser.id, onMessageHandler);
        }

        return () => {
            if (currentUser) {
                unsubscribe(currentUser.id, onMessageHandler);
            }
        };
    }, [currentUser]);

    return (
        <Column className={s.userVerificationPopup}>
            <Column className={s.userVerificationPopupContent}>
                { popupState === UserVerificationPopupState.VERIFY && verificationUrl &&
                    <VerifyState theme={theme} verificationUrl={verificationUrl}/>
                }

                { popupState === UserVerificationPopupState.SUCCESS &&
                    <SuccessState/>
                }

                { popupState === UserVerificationPopupState.FAILED && verificationUrl &&
                    <FailState theme={theme} verificationUrl={verificationUrl}/>
                }
            </Column>

            <div
                className={cn(
                    s.closeButton,
                    {
                        [s.closeButtonMobileLayout]: isMobileLayoutRecommended,
                    },
                )}
                onClick={closeModal}
            >
                <IconCloseLine/>
            </div>
        </Column>
    );
};
