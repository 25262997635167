import React from 'react';
import { RouteObject } from 'react-router-dom';

import {
    MemePartySettings,
    ObsSettings,
    PageSettings,
    ReferralSettings,
    AlertsSettings,
    StickersSettings,
    ModerationSettings,
} from '@views';

export const ChannelRouting: Array<RouteObject> = [
    {
        path: '',
        element: <PageSettings/>,
    },
    {
        path: 'page',
        element: <PageSettings/>,
    },
    {
        path: 'obs',
        element: <ObsSettings/>,
    },
    {
        path: 'referrals',
        element: <ReferralSettings/>,
    },
    {
        path: 'moderation',
        element: <ModerationSettings/>,
    },
    {
        path: 'stickers',
        element: <StickersSettings/>,
    },
    {
        path: 'alerts',
        element: <AlertsSettings/>,
    },
    {
        path: 'meme-party',
        element: <MemePartySettings/>,
    },
];
