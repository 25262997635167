import {
    Column,
    Paginator,
    Row,
    ERowAlignment,
    Selector,
    Loader,
    EComponentSize,
    Label,
    EComponentColor,
} from '@hyperclap/ui';
import React from 'react';

import { IconBellSolid } from '@assets/images/svg';
import { DATE_FORMAT_LOCALE, DATE_FORMAT_SHORT } from '@common';
import { TApp } from '@hooks';
import { EventsFilterPreset, Period } from '@typings';
import { Empty, FilterButton } from '@views';

import s from './Desktop.scss';
import { EventsTable } from '../../components';

const I18NEXT_DAYS = 'common.days';

interface IDesktopProps extends TApp {}

export const Desktop = (props: IDesktopProps) => {
    const {
        currentUser: {
            currentUser,
        },
        events: {
            date,
            events,
            page,
            pagesCount,
            changeFilters,
            changePage,
            changePeriod,
        },
        navigation: {
            goToSupporter,
        },
        translation: { t },
    } = props;

    const EVENT_TYPE_FILTERS = [
        { caption: t('common.purchases'), value: EventsFilterPreset.PURCHASES },
        { caption: t('common.bonuses'), value: EventsFilterPreset.BONUSES },
        { caption: t('common.stickers'), value: EventsFilterPreset.STICKERS },
        { caption: t('common.meme-cannon'), value: EventsFilterPreset.MEME_CANNON },
        { caption: t('common.fullscreen'), value: EventsFilterPreset.FULLSCREEN },
        { caption: t('common.moderation'), value: EventsFilterPreset.MODERATION },
        { caption: t('common.notifications'), value: EventsFilterPreset.NOTIFICATIONS },
    ];

    const PERIODS = [
        { caption: `7 ${t(I18NEXT_DAYS)}`, value: Period.WEEK },
        { caption: `30 ${t(I18NEXT_DAYS)}`, value: Period.MONTH },
        { caption: `90 ${t(I18NEXT_DAYS)}`, value: Period.THREE_MONTH },
    ];

    const dataReady = currentUser && events;

    const onPeriodChanged = ({ value }: { value: Period }) => changePeriod(value);

    const onEventsPageChanged = (page: number) => changePage(page);

    return (
        <Column grow margin={{ top: 14 }}>
            <Row grow columnGap={20} alignment={ERowAlignment.STRETCH}>
                <Column grow rowGap={20}>
                    <Row className={s.header}>
                        <Column>
                            {t('common.events-for')}
                        </Column>
                        <Column>
                            {!date
                                ? <Selector
                                    valuesList={PERIODS}
                                    defaultSelectionIndex={1}
                                    hideListOnSelect
                                    margin={{ left: 7 }}
                                    className={s.periodSelector}
                                    onSelectionChange={onPeriodChanged}
                                />
                                : <Label
                                    caption={new Date(date).toLocaleDateString(DATE_FORMAT_LOCALE, DATE_FORMAT_SHORT)}
                                    className={s.periodSelector}
                                    color={EComponentColor.ACCENT}
                                    padding={{ left: 7, right: 7 }}
                                    size={EComponentSize.LARGE}
                                />
                            }
                        </Column>
                        <Column margin={{ left: 7 }}>
                            <FilterButton
                                valuesList={EVENT_TYPE_FILTERS}
                                onSelectionChange={(val) => changeFilters(val.map((v) => v.value))}
                            />
                        </Column>
                        <Column grow/>
                        {pagesCount > 1 &&
                            <Column margin={{ left: 20 }}>
                                <Paginator
                                    page={page}
                                    pagesCount={pagesCount}
                                    maxDisplayedPages={10}
                                    showNavigation
                                    onChangePage={onEventsPageChanged}
                                />
                            </Column>
                        }
                    </Row>
                    <Row>
                        { dataReady
                            ? events?.length === 0
                                ? <Empty
                                    padding={70}
                                    icon={<IconBellSolid/>}
                                    iconSize={40}
                                    text={t('events.emptyText')}
                                />
                                : <EventsTable
                                    data={events}
                                    currentUser={currentUser}
                                    onClick={(eventData) => eventData.userId ? goToSupporter(eventData.userId) : {}}
                                />
                            : <Loader size={EComponentSize.LARGE} />
                        }
                    </Row>
                </Column>
            </Row>
        </Column>
    );
};
