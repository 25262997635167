import {
    Button,
    Column,
    EComponentColor,
    ERowJustification, ETheme,
    Row,
} from '@hyperclap/ui';
import cn from 'classnames';
// eslint-disable-next-line import/no-unresolved
import { t } from 'i18next';
import React from 'react';
import { Trans } from 'react-i18next';
import QRCode from 'react-qr-code';

import { castToArray } from '@common';

import s from './VerifyState.scss';

interface IVerifyStateProps {
    verificationUrl: string;
    theme: ETheme;
}

export const VerifyState = (props: IVerifyStateProps) => {
    const {
        verificationUrl,
        theme,
    } = props;

    const onVerifyClick = async () => {
        if (verificationUrl) {
            window.open(verificationUrl, '_blank');
        }
    };

    return (
        <>
            <Row className={cn(s.popupRow, s.description)}>
                <Column>
                    <Row justification={ERowJustification.CENTER}>
                        {verificationUrl &&
                            <QRCode
                                className={cn(s.iconQr,
                                    {
                                        [s.iconQrWhite]: theme === ETheme.DARK,
                                    },
                                )}
                                size={256}
                                bgColor={'transparent'}
                                fgColor={'inherit'}
                                style={{ marginBottom: 30 }}
                                value={verificationUrl}
                                viewBox={`0 0 256 256`}
                            />
                        }
                    </Row>
                    <Row>
                        <div className={s.title}>
                            {
                                castToArray<string>(t('user-verification-popup.verification-request-title', { returnObjects: true }))
                                    .map((element, idx) => (
                                        <Trans
                                            key={idx}
                                            i18nKey={element}
                                            components={[<div key={idx} />]}>
                                            {element}
                                        </Trans>
                                    ))
                            }
                        </div>
                    </Row>
                    <Row>
                        <div>
                            {
                                castToArray<string>(t('user-verification-popup.verification-request-text', { returnObjects: true }))
                                    .map((element, idx) => (
                                        <Trans key={idx} i18nKey={element} components={[<strong key={idx} />]}>
                                            {element}
                                        </Trans>
                                    ))
                            }
                        </div>
                    </Row>
                </Column>
            </Row>

            <Row className={cn(s.popupRow, s.button)} style={{ marginTop: 20 }}>
                <Button
                    caption={<div>{t('user-verification-popup.verify-button')}</div>}
                    color={EComponentColor.INFO}
                    disabled={!verificationUrl}
                    onClick={onVerifyClick}
                />
            </Row>
        </>
    );
};
