import { useLogger } from '@hyperclap/ui';
import { useEffect, useState } from 'react';

import { api } from '@store';
import { IAuthTokensResponse, TNullable } from '@typings';

interface TelegramParams {
    initData?: object;
    skip?: boolean;
}

export const useTelegram = (params: TelegramParams) => {
    const { initData, skip = false } = params;
    const logger = useLogger({ target: useTelegram.name, showTimestamp: true });

    const {
        telegram: {
            useLazyTelegramBotAuthQuery,
            useLazyWebAppAuthQuery,
            useLazyVerifyAuthQuery,
        },
    } = api;

    const [authData, setAuthData] = useState<TNullable<IAuthTokensResponse>>(null);

    const [authRequest] = useLazyTelegramBotAuthQuery();
    const [webAppAuthRequest] = useLazyWebAppAuthQuery();
    const [verifyAuthRequest] = useLazyVerifyAuthQuery();

    const authLoginRequest = (queryString?: string) => {
        return authRequest(queryString).unwrap();
    };

    const webAppAuthCheckRequest = async (initData: object) => {
        const authData = await webAppAuthRequest(initData).unwrap();
        setAuthData(authData);
    };

    const verifyRequest = async (initData: object): Promise<TNullable<boolean>> => {
        return (await verifyAuthRequest(initData).unwrap())?.valid;
    };

    useEffect(() => {
        if (initData && !skip) {
            logger.debug('Trying to telegram web app auth...');

            void webAppAuthCheckRequest(initData);
        }
    }, [skip]);

    return {
        authData,
        webAppAuthCheckRequest,
        verifyRequest,
        authLoginRequest,
    };
};
