import {
    EComponentSize,
    Icon,
    // ITextFieldAction,
    // ITextFieldMethods,
    // LoggerColor,
    // useLogger,
} from '@hyperclap/ui';
import React, { useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import {
    // IconArrowRightLine,
    // IconCloseLine,
    IconMagnifierLine,
} from '@assets/images/svg';
import { declensionUserSupporter, STICKER_SEARCH_QUERY_NAME } from '@common';
import { Avatar } from '@components';
import { TApp } from '@hooks';
import { SearchField } from '@memealerts/views/templates/PersonalAreaPage/components/SearchField/SearchField';

import s from './StreamerInfoBlockMobile.scss';

// enum SearchTextFieldAction {
//     ENTER = 'enter',
//     RESET = 'reset',
// }

interface IStreamerInfoBlockProps extends TApp {}

export const StreamerInfoBlockMobile = (props: IStreamerInfoBlockProps) => {
    // const logger = useLogger({ target: StreamerInfoBlockMobile.name, showTimestamp: true });
    const [searchParams] = useSearchParams();
    const query = searchParams.get(STICKER_SEARCH_QUERY_NAME) ?? '';

    const {
        // deviceInfo: {
        //     isAppleMobileDevice,
        // },
        // navigation: {
        //     // goToStreamerPage,
        //     gotoStreamerPageSearchResult,
        // },
        streamer: {
            streamer,
            state: {
                isLoading: isStreamerLoading,
            },
        },
        streamerArea: {
            stickers: {
                search: {
                    searchQuery,
                    changeSearchQuery,
                },
            },
            supporters: {
                supportersCount,
                isSupportersCountLoading,
            },
        },
        translation: {
            t,
        },
    } = props;

    // const {
    //     setIsStreamerPageFloatingHeaderVisible,
    // } = useAppActions();

    // const [enteredSearchQuery, setEnteredSearchQuery] = useState(query || '');
    // const searchInputMethodsRef = useRef<ITextFieldMethods>(null);

    // const onSearchTextFieldAction = (action: ITextFieldAction) => {
    //     switch (action.name) {
    //         case SearchTextFieldAction.RESET:
    //             changeSearchQuery('');
    //             goToStreamerPage(streamer?.channel.link);

    //             return true;
    //         case SearchTextFieldAction.ENTER:
    //             changeSearchQuery(enteredSearchQuery);
    //             gotoStreamerPageSearchResult(streamer?.channel.link, enteredSearchQuery);
    //             break;
    //     }
    // };

    // const onLostFocus = () => {
    //     // TODO: Implement platform detection and use it only for Android devices
    //     if (!isAppleMobileDevice) {
    //         logger.trace('Apply Android devices workaround to handle Enter/Tab/Return key on the virtual keyboard', LoggerColor.TEAL);
    //         changeSearchQuery(enteredSearchQuery);
    //         gotoStreamerPageSearchResult(streamer?.channel.link, enteredSearchQuery);
    //     } else {
    //         logger.trace('Skip Android devices workaround to handle Enter/Tab/Return key on the virtual keyboard', LoggerColor.TEAL);
    //     }
    // };

    // useEffect(() => {
    //     setIsStreamerPageFloatingHeaderVisible(!watchdogIsOnScreen);
    // }, [watchdogIsOnScreen]);

    useEffect(() => {
        if (query !== searchQuery) {
            changeSearchQuery(query);
        }

        // setEnteredSearchQuery(query);
    }, [query]);

    return (
        <>
            <div className={s.info}>
                <Link className={s.link} to={`/${streamer?.channel.link}`}>
                    <Avatar
                        source={streamer?.channel.avatarUrl}
                        preventShowDefault={isStreamerLoading || streamer?.channel.avatarUrl !== ''}
                        className={s.avatar}
                    />
                </Link>

                <Link className={s.name} to={`/${streamer?.channel.link}`}>
                    {streamer?.channel.name}
                </Link>

                {!isSupportersCountLoading && !!supportersCount &&
                    <div className={s.count}>
                        {supportersCount} {declensionUserSupporter(supportersCount, streamer)}
                    </div>
                }
            </div>
            <form className={s.search}>
                <SearchField
                    gray
                    left={<Icon icon={<IconMagnifierLine/>} size={EComponentSize.SMALL} />}
                >
                    <input
                        type="search"
                        placeholder={t('streamer-area.find-stickers')}
                        defaultValue={searchQuery}
                    />
                </SearchField>
            </form>
            <div className={s.searchShadow} />
        </>
    );
};
