import {
    useClickOutside,
    useModals,
} from '@hyperclap/ui';
import React, { useRef, useState } from 'react';

import { Avatar } from '@components';
import { TApp } from '@hooks';
import { Login } from '@memealerts/views';
import { BalanceButton, EarnBonusButton, ProfileMenu, WelcomeBonusPopup } from '@views';
import { UserVerificationPopup } from '@views/modals/UserVerificationPopup';

import s from './MobileHeader.scss';

interface IHeaderProps extends TApp {
    showStreamerAvatar?: boolean;
    headerRef?: React.RefObject<HTMLElement>;
}

export const MobileHeader = (props: IHeaderProps) => {
    const {
        balance: {
            balanceLoaded,
            earnWelcomeBonus,
            reloadBalance,
            welcomeBonusEarned,
        },
        currentUser: {
            currentUser,
            logout,
        },
        navigation: {
            goToStreamerPage,
        },
        streamer: {
            streamer,
            state: {
                isLoading: isStreamerLoading,
            },
        },
        settings: {
            system,
        },
        showStreamerAvatar,
    } = props;

    const { showModal } = useModals();

    const avatarRef = useRef<HTMLDivElement>(null);

    useClickOutside({
        controlledElementRef: avatarRef,
        handler: () => setIsMenuVisible(false),
    });

    const [isMenuVisible, setIsMenuVisible] = useState(false);

    const onEarnWelcomeBonusClick = async () => {
        if (!currentUser) {
            showModal({
                content: <Login returnUrl={location.pathname} {...props}/>,
                options: {
                    bodyClassName: s.loginModalBody,
                },
            });
        }

        if (currentUser && streamer) {
            if (streamer.channel.welcomeBonusVerificationEnabled && !currentUser.isUserVerified) {
                showModal(<UserVerificationPopup
                    currentUser={currentUser}
                    isMobileLayoutRecommended
                    reloadBalance={reloadBalance}
                />);
            } else {
                const result = await earnWelcomeBonus(streamer.id).unwrap();

                if (result.welcomeBonusEarned) {
                    reloadBalance();
                    showModal(<WelcomeBonusPopup streamer={streamer} />);
                }
            }
        }
    };

    return (
        <div>
            {showStreamerAvatar &&
                <Avatar
                    source={streamer?.channel.avatarUrl}
                    preventShowDefault={isStreamerLoading || streamer?.channel.avatarUrl !== ''}
                    className={s.streamerAvatar}
                    onClick={() => goToStreamerPage(streamer?.channel.link)}
                />
            }

            {currentUser
                ? <div>
                    <div>
                        { streamer && balanceLoaded && !streamer.channel.disableStickers
                            ? welcomeBonusEarned || !streamer?.channel.welcomeBonusEnabled || !system.asBool('bonuses.enableWelcomeBonus')
                                ? <BalanceButton {...props}/>
                                : <EarnBonusButton onClick={onEarnWelcomeBonusClick}/>
                            : null
                        }
                    </div>

                    <Avatar
                        source={currentUser?.avatar}
                        ref={avatarRef}
                        rounded
                        isUserAvatar
                        onClick={() => setIsMenuVisible(!isMenuVisible)}
                    />
                    {isMenuVisible && currentUser &&
                        <ProfileMenu className={s.profileMenu} currentUser={currentUser} onLogout={logout}/>
                    }
                </div>
                : <div>
                    <EarnBonusButton onClick={onEarnWelcomeBonusClick} />
                </div>
            }
        </div>
    );
};
