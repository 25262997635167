import React, { useState } from 'react';

import { ISticker } from '@typings';

import { ControlBlacklist, ControlDelete, ControlSettings } from './components';
import s from './Controls.scss';
import { EStickerPopupControlsMode } from '../../../enums';
import { IStickerPopupDataExtended } from '../../../types';

interface IControlsProps extends IStickerPopupDataExtended {
    sticker: ISticker;
    controlsMode: EStickerPopupControlsMode;
    updateSticker(stickerData: ISticker): void;
}

const initialPopupsState = {
    settings: false,
    delete: false,
    report: false,
};

export const Controls = (props: IControlsProps) => {
    const {
        currentUser: { currentUser },
        streamer: { streamer },
        sticker,
        controlsMode,
        updateSticker,
    } = props;
    const [popups, setPopups] = useState(initialPopupsState);
    const isOwner = sticker.creatorId === currentUser?.id;
    const isOwnerPage = streamer?.id === currentUser?.id;
    const isSettingsDisplayed = (controlsMode === EStickerPopupControlsMode.STREAMER) ||
        (controlsMode === EStickerPopupControlsMode.SEND && isOwnerPage);
    const isBlacklistDisplayed = controlsMode === EStickerPopupControlsMode.STREAMER;

    const showPopup = (options: Partial<typeof popups>) => {
        setPopups({
            ...initialPopupsState,
            ...options,
        });
    };

    const handleSettingsClick = () => showPopup({ settings: true });

    const handleSettingsClose = () => showPopup({ settings: false });

    const handleDeleteClick = () => showPopup({ delete: true });

    const handleDeleteClose = () => showPopup({ delete: false });

    // const handleReportClick = () => showPopup({ report: true });
    //
    // const handleReportClose = () => showPopup({ report: false });

    return (
        <div className={s.root}>
            {/* <ControlReport*/}
            {/*    sticker={sticker}*/}
            {/*    isPopupDisplayed={popups.report}*/}
            {/*    onClick={handleReportClick}*/}
            {/*    onClose={handleReportClose}*/}
            {/* />*/}

            {isBlacklistDisplayed && (
                <ControlBlacklist
                    sticker={sticker}
                    currentUser={currentUser}
                    updateSticker={updateSticker}
                />
            )}

            {isSettingsDisplayed && (
                <ControlSettings
                    sticker={sticker}
                    currentUser={currentUser}
                    isPopupDisplayed={popups.settings}
                    updateSticker={updateSticker}
                    onClick={handleSettingsClick}
                    onClose={handleSettingsClose}
                />
            )}


            {isOwner && (
                <ControlDelete
                    sticker={sticker}
                    isPopupDisplayed={popups.delete}
                    onClick={handleDeleteClick}
                    onClose={handleDeleteClose}
                />
            )}
        </div>
    );
};
