import { Column, EColumnAlignment, EColumnJustification } from '@hyperclap/ui';
import * as React from 'react';

import s from './AmouranthEmptyState.scss';
import HappyGif from './assets/happy.gif';


export const AmouranthEmptyState = () => {
    return (
        <Column
            className={s.root}
            justification={EColumnJustification.CENTER}
            alignment={EColumnAlignment.CENTER}
        >
            <img className={s.image} src={HappyGif}/>

            <div className={s.text}>
                Dear Amouranth,
                <br/>
                <br/>
                We heard from Ilya Maddyson that you’re interested in our project — such exciting news!
                <br/>
                <br/>
                Over the next few months, we’re planning to launch in the U.S., and we’d be absolutely thrilled to have you on board.
                <br/>
                <br/>
                You can reach us:
                <ul>
                    <li>via email: <a href="mailto:admin@memealerts.com">admin@memealerts.com</a> or <a href="mailto:memealertscom@gmail.com">memealertscom@gmail.com</a></li>
                    <li>on Discord: <a href="https://discord.com/users/1081148841606250586" target="_blank" rel="noreferrer">@foxintheboxxx</a></li>
                    <li>on Telegram: <a href="https://t.me/memealertsup" target="_blank" rel="noreferrer">@memealertsup</a></li>
                </ul>

                Huge thanks! We’d love to tell you more about the project. Wishing you a fantastic day!

                <br/>
                <br/>
                Still buzzing with excitement,
                <br/>MemeAlerts Team
            </div>
        </Column>
    );
};
