import { DrawerListItem } from '@hyperclap/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import {
    IconStarLine,
    IconLogoutLine,
} from '@assets/images/svg';
import { useApi, useAuth } from '@hooks';
import { Logo, LogoKind } from '@views';

import s from './ProfileMenuMobile.scss';

interface IProfileMenuProps {
    onLogout: () => Promise<void>;
}

export const ProfileMenuMobile = (props: IProfileMenuProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { isTelegramWebApp } = useAuth();

    const {
        onLogout,
    } = props;

    const {
        util,
    } = useApi();

    const navigate = useNavigate();

    const onExitClick = async () => {
        await onLogout();
        dispatch(util.resetApiState());
        localStorage.removeItem('token');
        navigate('/');
    };

    return (
        <>
            <div className={s.menu}>
                <Logo
                    kind={LogoKind.LIGHT}
                    grayscale
                    className={s.logoDrawer}
                    width={70}
                />
                <Link to="/dashboard">
                    <DrawerListItem
                        icon={<IconStarLine />}
                    >
                        {t('common.my-streamers')}
                    </DrawerListItem>
                </Link>
            </div>

            {!isTelegramWebApp &&
                <>
                    <div className={s.divider} />
                    <DrawerListItem
                        onClick={onExitClick}
                        icon={<IconLogoutLine />}
                    >
                        {t('common.logout')}
                    </DrawerListItem>
                </>
            }
            {/* <DrawerListItem icon={<IconCogLine/>} to="/dashboard">
                {t('common.streamers')}
            </DrawerListItem>
            <div className="footer"></div>
            {!isTelegramWebApp &&
                <DrawerListItem icon={<IconLogoutLine/>} onClick={onExitClick}>
                    {t('common.logout')}
                </DrawerListItem>
            } */}
        </>
    );
};
