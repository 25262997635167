import {
    Button,
    Column,
    EColumnAlignment,
    EComponentColor,
    EComponentSize,
} from '@hyperclap/ui';
import React, { useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';

import { IconExclamationOctagonLine, IconSmileSadSolid } from '@assets/images/svg';
import { useApp, useAppActions } from '@hooks';
import { useStickers } from '@hooks/app/stickers';
import { useAppSelector } from '@hooks/store/AppSelector';
import { StreamerAreaPageMobile } from '@memealerts/views/templates';
import {
    ISticker,
    IUser,
    StickerSendResult,
    StickerSendTopic,
} from '@typings';
import {
    Empty,
    EStickerPopupControlsMode,
    NotSentPopup,
    PremoderationPopup,
    StreamerInfoBlockMobile,
    useStickerPopup,
} from '@views';

// import s from './StreamerArea.scss';

enum ELoadNextPages {
    catalogueLoadNextPage,
    channelLoadNextPage,
    favoritesLoadNextPage,
    lastSentLoadNextPage,
    mineLoadNextPage,
    popularLoadNextPage,
    searchLoadNextPage,
}
type TLoadNextPages = Record<keyof typeof ELoadNextPages, () => Promise<void>>;

export const StreamerAreaMobile = () => {
    const app = useApp();
    const {
        streamerChannelName,
        currentUser: {
            currentUser,
        },
        events: {
            registerStreamerPageOpenEvent,
        },
        navigation: {
            goToHome,
        },
        stickers: {
            selectedSticker,
            resetStickerToSend,
            topic,
            setStickerToSend,
        },
        streamer: {
            streamer,
            noStreamer,
        },
        streamerArea: {
            stickers: {
                catalogue: {
                    isEnd: catalogueIsEnd,
                    stickers: catalogueStickers,
                    loadNextPage: catalogueLoadNextPage,
                },
                channel: {
                    isEnd: channelIsEnd,
                    stickers: channelStickers,
                    loadNextPage: channelLoadNextPage,
                },
                favorites: {
                    isEnd: favoritesIsEnd,
                    stickers: favoriteStickers,
                    loadNextPage: favoritesLoadNextPage,
                },
                lastSent: {
                    isEnd: lastSentIsEnd,
                    stickers: lastSentStickers,
                    loadNextPage: lastSentLoadNextPage,
                },
                mine: {
                    isEnd: mineIsEnd,
                    stickers: mineStickers,
                    loadNextPage: mineLoadNextPage,
                },
                popular: {
                    isEnd: popularIsEnd,
                    stickers: popularStickers,
                    loadNextPage: popularLoadNextPage,
                },
                search: {
                    isEnd: searchIsEnd,
                    stickers: searchStickers,
                    loadNextPage: searchLoadNextPage,
                },
            },
        },
        translation: {
            t,
        },
    } = app;
    const STICKERS_MAP: Partial<Record<StickerSendTopic, ISticker[]>> = {
        [StickerSendTopic.TOP]: catalogueStickers,
        [StickerSendTopic.POPULAR]: popularStickers,
        [StickerSendTopic.LAST]: lastSentStickers,
        [StickerSendTopic.CHANNEL]: channelStickers,
        [StickerSendTopic.FAVORITES]: favoriteStickers,
        [StickerSendTopic.MY_CREATED]: mineStickers,
        [StickerSendTopic.SEARCH]: searchStickers,
    };
    const stickersRef = useRef<ISticker[]>([]);
    const loadNextPagesRef = useRef<Partial<TLoadNextPages>>({});

    const {
        changeStickerCustomSettings,
        addStickerToFavorites,
        removeStickerFromFavorites,
        addStickerToChannel,
        removeStickerFromChannel,
        deleteSticker,
    } = useStickers({
        streamerChannelName: currentUser?.channel?.name,
    });

    const stickerSendResult = useAppSelector((state) => state.app.stickerSendResult);
    const isSendButtonAnimationActive = useAppSelector((state) => state.app.isSendButtonAnimationActive);
    const { resetStickerSendResult } = useAppActions();

    const updateStickerFavoriteState = async (sticker: ISticker) => {
        if (sticker.isFavorite) {
            return removeStickerFromFavorites(sticker);
        } else {
            return addStickerToFavorites(sticker);
        }
    };

    const updateStickerInChannelState = async (sticker: ISticker) => {
        if (sticker.isAddedToChannel) {
            return removeStickerFromChannel(sticker);
        } else {
            return addStickerToChannel(sticker);
        }
    };

    const handleStickerPopupClose = () => resetStickerToSend();

    const goToNextSticker = (currentSticker?: ISticker) => {
        const stickers = stickersRef.current;
        const index = stickers.findLastIndex((item) => item.id === currentSticker?.id);
        const nextSticker = stickers[index + 1];

        // console.log(`${index + 1} / ${stickers.length}`);

        if (nextSticker) {
            setStickerToSend(nextSticker, streamer as IUser, topic);
        }

        if (index === stickers.length - 2) {
            if (topic === StickerSendTopic.TOP && !catalogueIsEnd) {
                void loadNextPagesRef.current?.catalogueLoadNextPage?.();
            } else if (topic === StickerSendTopic.CHANNEL && !channelIsEnd) {
                void loadNextPagesRef.current?.channelLoadNextPage?.();
            } else if (topic === StickerSendTopic.FAVORITES && !favoritesIsEnd) {
                void loadNextPagesRef.current?.favoritesLoadNextPage?.();
            } else if (topic === StickerSendTopic.LAST && !lastSentIsEnd) {
                void loadNextPagesRef.current?.lastSentLoadNextPage?.();
            } else if (topic === StickerSendTopic.MY_CREATED && !mineIsEnd) {
                void loadNextPagesRef.current?.mineLoadNextPage?.();
            } else if (topic === StickerSendTopic.POPULAR && !popularIsEnd) {
                void loadNextPagesRef.current?.popularLoadNextPage?.();
            } else if (topic === StickerSendTopic.SEARCH && !searchIsEnd) {
                void loadNextPagesRef.current?.searchLoadNextPage?.();
            }
        }
    };

    const goToPrevSticker = (currentSticker?: ISticker) => {
        const stickers = stickersRef.current;
        const index = stickers.findLastIndex((item) => item.id === currentSticker?.id);
        const prevSticker = stickers[index - 1];

        if (prevSticker) {
            setStickerToSend(prevSticker, streamer as IUser, topic);
        }
    };

    const {
        isOpened: isStickerPopupOpened,
        showStickerPopup,
        setStickerPopupItem,
    } = useStickerPopup({
        ...app,
        updateCustomSettings: changeStickerCustomSettings,
        updateFavoriteState: updateStickerFavoriteState,
        updateInChannelState: updateStickerInChannelState,
        deleteSticker: deleteSticker,
        controlsMode: EStickerPopupControlsMode.SEND,
        onClose: handleStickerPopupClose,
        goToNextSticker,
        goToPrevSticker,
    });

    useEffect(() => {
        if (selectedSticker) {
            setStickerPopupItem(selectedSticker);

            if (!isStickerPopupOpened) {
                showStickerPopup();
            }
        }
    }, [selectedSticker]);

    useEffect(() => {
        if (streamer && streamer.channel.name) {
            document.title = `${streamer.channel.name} - MemeAlerts`;
        }

        if (streamer && currentUser && streamer.channel.link) {
            registerStreamerPageOpenEvent({
                streamerId: streamer.id,
                streamerName: streamer.channel.name,
                userId: currentUser.id,
            });
        }
    }, [streamer, currentUser]);

    useEffect(() => {
        stickersRef.current = STICKERS_MAP[topic] as ISticker[];
    }, [topic, STICKERS_MAP]);

    useEffect(() => {
        loadNextPagesRef.current = {
            ...loadNextPagesRef.current,
            catalogueLoadNextPage,
            channelLoadNextPage,
            favoritesLoadNextPage,
            lastSentLoadNextPage,
            mineLoadNextPage,
            popularLoadNextPage,
            searchLoadNextPage,
        };
    }, [
        catalogueLoadNextPage,
        channelLoadNextPage,
        favoritesLoadNextPage,
        lastSentLoadNextPage,
        mineLoadNextPage,
        popularLoadNextPage,
        searchLoadNextPage,
    ]);

    return (
        <StreamerAreaPageMobile {...app}>
            {streamer && <StreamerInfoBlockMobile {...app}/>}
            {noStreamer &&
                <Column rowGap={30} alignment={EColumnAlignment.CENTER}>
                    <Empty
                        icon={<IconExclamationOctagonLine/>}
                        iconSize={EComponentSize.EXTRA_LARGE}
                        text={t('streamer-area.channel-not-found', { streamerChannelName })}
                        padding={{ top: 100 }}
                    />
                    <Button
                        caption={t('streamer-area.to-home')}
                        color={EComponentColor.ACCENT}
                        icon={<IconSmileSadSolid/>}
                        width={200}
                        onClick={goToHome}
                    />
                </Column>
            }
            <Outlet context={app}/>

            { (stickerSendResult === StickerSendResult.PREMODERATION) && !isSendButtonAnimationActive &&
                <PremoderationPopup
                    mobileLayout
                    onCloseClick={resetStickerSendResult}
                />
            }

            { (stickerSendResult === StickerSendResult.BANNED_TOPIC) && !isSendButtonAnimationActive &&
                <NotSentPopup
                    mobileLayout
                    onCloseClick={resetStickerSendResult}
                />
            }
        </StreamerAreaPageMobile>
    );
};
