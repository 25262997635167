import { useEffect } from 'react';

export const SuccessPaymentRedirect = () => {
    if (window.opener) {
        window.opener.postMessage(
            JSON.stringify({ action: 'paymentSuccess' }),
        );
    }

    useEffect(() => {
        setTimeout(() => {
            window.close();
        }, 500);
    }, []);

    return null;
};
