import { ETheme, ThemeContext } from '@hyperclap/ui';
import React, { useContext, useLayoutEffect } from 'react';

import { useApp, useAuth, useOneShot } from '@hooks';
import { METRIC_LANDING_OPENED } from '@hooks/app/events';

import { Desktop, Mobile, WebApp } from './layouts';


export const Landing = () => {
    const { changeTheme } = useContext(ThemeContext);
    const { isTelegramWebApp } = useAuth();
    const app = useApp();
    const {
        deviceInfo: {
            isMobileLayoutRecommended,
        },
        events: {
            registerFrontendEvent,
        },
    } = app;

    useOneShot({
        fn: () => registerFrontendEvent({
            eventName: METRIC_LANDING_OPENED,
            data: {
                labels: { device: isMobileLayoutRecommended ? 'mobile' : 'desktop' },
            },
        }),
    });

    useLayoutEffect(() => {
        changeTheme(ETheme.LIGHT, true);
    }, []);

    return (
        isMobileLayoutRecommended
            ? isTelegramWebApp
                ? <WebApp />
                : <Mobile {...app}/>
            : <Desktop {...app}/>
    );
};
