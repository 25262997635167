import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useApi } from '@hooks';
import { IHookBaseParams, IUser } from '@typings';

interface IStreamersParams extends IHookBaseParams {}

export const useStreamers = (params: IStreamersParams) => {
    const {
        active,
    } = params;

    const navigate = useNavigate();

    const {
        users: {
            useLazyFindStreamerQuery,
        },
    } = useApi();

    const [findStreamers, { isFetching: isStreamersFetching }] = useLazyFindStreamerQuery();

    const [streamers, setStreamers] = useState<Array<IUser>>();

    const searchParams = new URLSearchParams(location.search);
    const search = searchParams.get('search');
    const [query, setQuery] = useState(search ?? '');

    const setSearchQuery = (val: string) => {
        if (val) {
            navigate(`/dashboard?search=${val}`);
        } else {
            navigate('/dashboard');
        }

        setQuery(val);
    };

    useEffect(() => {
        if (active) {
            if (query) {
                findStreamers(query, false).unwrap().then((data) => {
                    console.log('res', data);
                    setStreamers(data);
                });
            } else {
                setStreamers(undefined);
            }
        }
    }, [query, active]);

    return {
        isStreamersFetching,
        searchQuery: query,
        streamers,
        setSearchQuery,
    };
};
