import * as React from 'react';

import { AuthButtons, Logo, LogoKind } from '@views';

import s from './WebApp.scss';

export const WebApp = () => {
    return (
        <div className={s.webApp}>
            <Logo kind={LogoKind.DARK} width={120} />

            <AuthButtons />
        </div>
    );
};
