import {
    EComponentSize,
    Icon,
    Drawer,
} from '@hyperclap/ui';
import cn from 'classnames';
import React, { FormEvent, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import {
    IconCloseLine,
    IconMagnifierLine,
} from '@assets/images/svg';
import { STREAMER_SEARCH_QUERY_NAME } from '@common';
import { MobileAvatar } from '@components/avatars/MobileAvatar';
import { TApp, useInvites } from '@hooks';
import { ProfileMenuMobile } from '@views';

import s from './MobileHeader.scss';
import { SearchField } from '../SearchField/SearchField';

interface IHeaderProps extends TApp {
    onSteamersSearch?: (search: string) => void;
}

export const MobileHeader = (props: IHeaderProps) => {
    const {
        onSteamersSearch,
        currentUser: {
            currentUser,
            logout,
        },
        translation: {
            t,
        },
    } = props;
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const query = searchParams.get(STREAMER_SEARCH_QUERY_NAME) ?? '';
    const [isMenuVisible, setIsMenuVisible] = useState(false);

    const {
        invitesEnabled,
        inviteActivated,
    } = useInvites();

    const [search, setSearch] = useState(query || '');
    const [isStreamer, setIsStreamer] = useState<boolean>();

    useEffect(() => {
        setIsStreamer(invitesEnabled ? inviteActivated : true);
    }, [invitesEnabled, inviteActivated]);


    const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const onSearchClear = () => {
        setSearch('');
        onSteamersSearch?.('');
    };

    const onSubmit = (event: FormEvent) => {
        event.preventDefault();
        onSteamersSearch?.(search);
    };

    const onLogout = async () => {
        await logout();
        navigate('/');
    };

    const searchIcon = (
        <Icon
            className={s.icon}
            icon={<IconMagnifierLine/>}
            size={EComponentSize.SMALL}
        />
    );
    const clearIcon =(
        <Icon
            className={cn([s.icon, s.close])}
            onClick={onSearchClear}
            icon={<IconCloseLine/>}
            size={EComponentSize.SMALL}
        />
    );
    const logoIcon = (<Link to={isStreamer ? '/dashboard' : '/stickers'}>
        <span className={s.logo} />
    </Link>);

    return (
        <div className={s.header}>
            <form onSubmit={onSubmit} className={s.form}>
                <SearchField
                    left={search ? searchIcon : logoIcon}
                    right={search ? clearIcon : null}
                >
                    <input
                        type="search"
                        placeholder={t('personal-area.header.find-streamers')}
                        value={search}
                        onChange={onSearchChange}
                    />
                </SearchField>
            </form>

            <MobileAvatar
                loading={!currentUser}
                src={currentUser?.avatar}
                onClick={() => setIsMenuVisible(true)}
            />

            <Drawer
                onClose={() => setIsMenuVisible(false)}
                show={isMenuVisible}
            >
                <ProfileMenuMobile
                    onLogout={onLogout}
                />
            </Drawer>
        </div>
    );
};
