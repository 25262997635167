import { noop } from '@hyperclap/utils';
import cn from 'classnames';
import React from 'react';

import { DEFAULT_SOUND_VOLUME } from '@common';
import { ISticker, SizeCalculationBase } from '@typings';
import { Sticker } from '@views';

import { StickerOverlay } from './components';
import s from './StickersGrid.scss';

interface StickersGridProps {
    className?: string,
    stickers: Array<ISticker>;
    stickersSoundEnabled?: boolean;
    columnsCount?: number;
    defaultSoundVolume?: number;
    onStickerClick?: (a: ISticker) => void;
    onSwitchSound?: () => void;
}

const SIZE = 250;

const calcHeight = (sticker: ISticker) => {
    const originWidth = sticker.videoData?.width ?? SIZE;
    const originHeight = sticker.videoData?.height ?? SIZE;
    const ratio = SIZE / originWidth;

    return Math.ceil(originHeight * ratio) + 4;
};

export const StickersGrid = (props: StickersGridProps) => {
    const {
        className,
        stickers,
        stickersSoundEnabled = false,
        defaultSoundVolume = DEFAULT_SOUND_VOLUME,
        onStickerClick = noop,
    } = props;

    const onStickerClickHandler = async (s: ISticker) => {
        onStickerClick(s);
    };

    return (
        <div className={cn(s.grid, className)}>
            {stickers.map((sticker) => (
                <div
                    className={s.item}
                    key={sticker.id}
                    style={{ '--span': calcHeight(sticker) } as React.CSSProperties}
                >
                    <Sticker
                        key={sticker.id}
                        sticker={sticker}
                        withSound={stickersSoundEnabled}
                        soundOnHover
                        loop
                        useFadeInOnLoaded
                        useSkeletonOnLoading
                        overlay={<StickerOverlay sticker={sticker}/>}
                        volume={sticker.customSettings?.customVolume || defaultSoundVolume}
                        sizeCalculationBase={SizeCalculationBase.WIDTH}
                        onClick={onStickerClickHandler}
                    />
                </div>
            ))}
        </div>
    );
};
