import { useLogger } from '@hyperclap/ui';
import { noop } from '@hyperclap/utils';
import React, { createContext, ReactElement, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
    AUTH_TOKEN_NAME,
    getCookie,
    OBS_TOKEN_NAME,
    REFRESH_TOKEN_NAME,
    TELEGRAM_AUTH_REQUEST_NAME,
    WebSocketClient,
} from '@common';
import { useTelegram } from '@hooks/app/telegram';
import { useAppActions } from 'src/base/hooks';
import { EAuthMode, IAuthData, TAuthContext } from 'src/base/typings';

declare global {
    interface Window {
        obsstudio?: object;
        Telegram?: {
            WebApp?: {
                initData?: object,
                ready: () => void,
                close: () => void,
                showAlert: (msg: string, cb: () => void) => void,
            }
        };
    }
}

const initialAuthData: IAuthData = {
    authMode: EAuthMode.DEFAULT,
    authToken: '',
    obsToken: '',
    refreshToken: '',
    isAuthInProgress: true,
    isTelegramWebApp: false,
};

interface IAuthProviderProps {
    redirectUnauthorizedTo: ReactElement;
}

export const AuthContext = createContext<TAuthContext>({ ...initialAuthData, clearTokens: noop, redirectUnauthorizedTo: '' });

export const AuthProvider = ({ children, redirectUnauthorizedTo }: React.PropsWithChildren<IAuthProviderProps>) => {
    const logger = useLogger({ target: AuthProvider.name, showTimestamp: true });
    const [query] = useSearchParams();
    const authDataRef = useRef<IAuthData>({ ...initialAuthData });
    const { updateMode } = useAppActions();
    const [isAuthInProgress, setAuthInProgress] = useState<boolean>(true);

    const authToken = query.get(AUTH_TOKEN_NAME);

    if (authToken) {
        localStorage.setItem(AUTH_TOKEN_NAME, authToken);
    }

    const refreshToken = query.get(REFRESH_TOKEN_NAME);

    if (refreshToken) {
        localStorage.setItem(REFRESH_TOKEN_NAME, refreshToken);
        authDataRef.current.refreshToken = refreshToken;
    }

    const obsToken = query.get(OBS_TOKEN_NAME);

    if (obsToken) {
        localStorage.setItem(OBS_TOKEN_NAME, obsToken);
        authDataRef.current.obsToken = obsToken;
    }

    if (!authToken && obsToken) {
        authDataRef.current.authMode = EAuthMode.OBS;
        updateMode(EAuthMode.OBS);
    }

    const providedAuthToken = localStorage.getItem(AUTH_TOKEN_NAME);

    if (providedAuthToken) {
        authDataRef.current.authToken = providedAuthToken;
    } else {
        if (window?.obsstudio && localStorage.getItem(OBS_TOKEN_NAME)) {
            logger.debug(`This is an OBS Studio embedded browser`);
            authDataRef.current.authMode = EAuthMode.OBS;
            updateMode(EAuthMode.OBS);
        }
    }

    const tgInitData = window.Telegram?.WebApp?.initData;
    const inTgWebAppCookie = getCookie('itg');

    authDataRef.current.isTelegramWebApp = !!(tgInitData || inTgWebAppCookie);

    const isAuthTokensPresent = !!authDataRef.current.authToken || !!authDataRef.current.obsToken;

    const {
        authData: tgAuthData,
        authLoginRequest,
        verifyRequest,
    } = useTelegram({ initData: tgInitData, skip: isAuthTokensPresent });

    const clearTokens = () => {
        logger.debug('Clear auth tokens');

        localStorage.removeItem(AUTH_TOKEN_NAME);
        localStorage.removeItem(REFRESH_TOKEN_NAME);
        localStorage.removeItem(OBS_TOKEN_NAME);
        authDataRef.current = { ...initialAuthData };

        if (tgAuthData || tgInitData) {
            logger.debug('Clear session storage [Telegram Web App]');

            sessionStorage.clear();
        }

        WebSocketClient.disconnect();
    };

    const hideLoader = () => {
        logger.debug('Hide loader');

        WebSocketClient.tryToReconnect();

        setAuthInProgress(false);
    };

    const loginToTelegram = async (query: URLSearchParams) => {
        logger.debug('Try to login to Telegram');

        await authLoginRequest();
        const tgAuth = query.get(TELEGRAM_AUTH_REQUEST_NAME);

        if (tgAuth) {
            window.Telegram?.WebApp?.close();
        } else {
            hideLoader();
        }
    };

    const verifyTelegramAccount = async (tgData: object, query: URLSearchParams) => {
        logger.debug('Try to verify Telegram account');

        const isValid = await verifyRequest(tgData);

        if (!isValid) {
            logger.debug('Telegram account NOT verified!');

            clearTokens();
        } else {
            logger.debug('Telegram account verified');

            const tgAuth = query.get(TELEGRAM_AUTH_REQUEST_NAME);

            if (tgAuth) {
                logger.debug('This is a Telegram authorization request, we show an alert');

                window.Telegram?.WebApp?.showAlert('Вы успешно авторизованы', () => {
                    window.Telegram?.WebApp?.close();
                });
            }

            window.Telegram?.WebApp?.ready();
            hideLoader();
        }
    };

    const tryLoginOrVerifyTelegramAccount = (query: URLSearchParams, forceVerify = false) => {
        logger.debug('Try to login or verify Telegram account');

        if ((!getCookie('tg') || forceVerify) && tgInitData && authDataRef.current.authToken) {
            void verifyTelegramAccount(tgInitData, query);
        } else if (getCookie('tg') && authDataRef.current.authToken) {
            void loginToTelegram(query);
        }
    };

    useEffect(() => {
        if (!isAuthTokensPresent && tgAuthData?.authToken) {
            logger.debug('Success Telegram web app auth');

            localStorage.setItem(AUTH_TOKEN_NAME, tgAuthData.authToken);
            localStorage.setItem(REFRESH_TOKEN_NAME, tgAuthData.refreshToken);
            authDataRef.current.refreshToken = tgAuthData.refreshToken;
            authDataRef.current.authToken = tgAuthData.authToken;

            tryLoginOrVerifyTelegramAccount(query, true);
        } else if (tgAuthData?.authToken === null) {
            logger.debug('Fail Telegram web app auth');

            hideLoader();
        }
    }, [tgAuthData]);

    useEffect(() => {
        logger.trace(`Build authorization context`);

        logger.trace(`This is an Telegram WebApp browser: ${authDataRef.current.isTelegramWebApp}`);

        if (
            (isAuthTokensPresent && !tgInitData && !getCookie('tg') && !query.get(TELEGRAM_AUTH_REQUEST_NAME)) ||
            (!isAuthTokensPresent && !tgInitData)) {
            logger.debug('Empty tokens state');

            hideLoader();
        } else if (tgInitData || getCookie('tg')) {
            tryLoginOrVerifyTelegramAccount(query);
        }

        return () => logger.trace(`Destroy authorization context`);
    }, []);

    const value: TAuthContext = { ...authDataRef.current, isAuthInProgress, clearTokens, redirectUnauthorizedTo };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};
