import cn from 'classnames';
import React, { useRef } from 'react';

import { ISticker } from '@typings';

import s from './MobileSticker.scss';

interface MobileStickerProps {
    // overlay?: JSX.Element;
    sticker: ISticker;
    // useRecalculateDimensions?: boolean;
    // volume?: number;
    // videoRef?: React.MutableRefObject<HTMLVideoElement | null | undefined>;
    // useFallbackVideo?: boolean;
    // sizeCalculationBase?: SizeCalculationBase;
    // forceEnabled?: boolean;
    onClick?: (sticker: ISticker) => void;
    // onLoaded?: (sticker: ISticker) => void;
}

const calcHeight = (sticker: ISticker) => {
    const size = (window.innerWidth - 18) / 2;

    const originWidth = sticker.videoData?.width ?? size;
    const originHeight = sticker.videoData?.height ?? size;
    const ratio = size / originWidth;

    return Math.ceil(originHeight * ratio) + 4;
};

export const MobileSticker = (props: MobileStickerProps) => {
    const {
        // overlay,
        // sizeCalculationBase = SizeCalculationBase.NONE,
        sticker,
        // useAnimatedPreview = false,
        // useRecalculateDimensions = true,
        onClick,
        // onLoaded,
    } = props;


    // const stickerImageRef = useRef<HTMLImageElement>(null);
    const stickerRef = useRef<HTMLDivElement>(null);

    // const [_videoDims, setVideoDims] = useState<IDimensions>(DEFAULT_DIMENSIONS);

    // const onReadyStateChanged = (ready: boolean) => {
    //     setReady(ready);
    //     onLoaded?.(sticker);
    // };

    // const recalculateDimensions = () => {
    //     switch (sizeCalculationBase) {
    //         case SizeCalculationBase.WIDTH:
    //             if (stickerRef.current?.clientWidth && sticker.videoData) {
    //                 setVideoDims({
    //                     width: stickerRef.current.clientWidth,
    //                     height: stickerRef.current.clientWidth / sticker.videoData.aspectRatio,
    //                 });
    //             }

    //             break;
    //         case SizeCalculationBase.HEIGHT:
    //             if (stickerRef.current?.clientHeight && sticker.videoData) {
    //                 setVideoDims({
    //                     width: stickerRef.current.clientHeight * sticker.videoData.aspectRatio,
    //                     height: stickerRef.current.clientHeight,
    //                 });
    //             }

    //             break;
    //     }
    // };

    // useEffect(() => {
    //     if (useRecalculateDimensions) {
    //         recalculateDimensions();
    //     }
    // }, []);

    return (
        <div
            ref={stickerRef}
            className={cn(
                s.sticker,
            )}
            style={{ '--span': calcHeight(sticker) } as React.CSSProperties}
            onClick={() => onClick?.(sticker)}
        >
            <img
                className={s.image}
                src={sticker.stickerAnimatedPreview}
                // stickerImageRef={stickerImageRef}
                // onReadyStateChange={onReadyStateChanged}
            />
            {/* {overlay} */}
            {/* {ready && sticker.moderationState.toLowerCase() === 'moderation' &&
                <div className={s.moderationPlate}>{t('personal-area.mine.moderation')}</div>
            }
            {ready && sticker.moderationState.toLowerCase() === 'declined' &&
                <div className={s.declinedPlate}>{t('personal-area.mine.rejected')}</div>
            }
            {ready && sticker.customSettings?.disableSticker &&
                <div className={s.blacklistPlate}>{t('personal-area.mine.blacklisted')}</div>
            } */}
        </div>
    );
};
