import { baseApi } from './base.api';

/** Verification API provides methods are user to verify account */
export const verificationApi = baseApi
    .injectEndpoints({
        endpoints: (build) => ({

            /** Sends a request to get a verification URL */
            getVerificationUrl: build.query<{ url: string }, void>({
                query: () => ({
                    url: 'verification/url',
                    method: 'GET',
                }),
            }),

            /** Sends a request to reset verification */
            resetVerification: build.query<{ success: boolean }, void>({
                query: () => ({
                    url: 'verification/reset',
                    method: 'POST',
                }),
            }),
        }),
    });
