import React from 'react';

import { useAppContext } from '@hooks';

import { StreamerPageDesktop } from './StreamerPageDesktop';
import { StreamerPageMobile } from './StreamerPageMobile';

export const StreamerPage = () => {
    const app = useAppContext();

    if (app.deviceInfo.isMobileLayoutRecommended) {
        return (<StreamerPageMobile />);
    }

    return (<StreamerPageDesktop />);
};
