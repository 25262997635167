import React from 'react';

import { TApp, useAuth } from '@hooks';
import { IStreamer } from '@typings';
import { StreamersListMobile } from '@views';
import { AmouranthEmptyState, useAmouranthEmptyState } from '@views/fragments/AmouranthEmptyState';

interface IMobileProps extends TApp {}

export const Mobile = (props: IMobileProps) => {
    const {
        personalArea: {
            supporters: {
                supportedStreamers,
                isSupportersFetching,
            },
            streamers: {
                streamers,
                isStreamersFetching,
            },
        },
    } = props;

    const { isAmouranth } = useAmouranthEmptyState();
    const { isTelegramWebApp } = useAuth();

    let items: (IStreamer[]) | undefined = undefined;

    if (!isAmouranth && !isStreamersFetching && !isSupportersFetching) {
        if (streamers?.length) {
            items = streamers.map(({ id, channel }) => ({
                id,
                name: channel.name,
                link: channel.link,
                avatar: channel.avatarUrl,
            }));
        } else if (supportedStreamers) {
            items = supportedStreamers?.map((streamer) => ({
                id: streamer.streamerId,
                name: streamer.streamerName,
                link: streamer.streamerLink,
                avatar: streamer.streamerAvatar,
            }));
        }
    }

    return (
        isAmouranth
            ? <AmouranthEmptyState />
            : <StreamersListMobile
                openInNewTab={!isTelegramWebApp}
                streamers={items}
            />
    );
};
