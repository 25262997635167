import { useEffect, useState } from 'react';

import { useApi, useCurrentUser } from '@hooks';
import { IHookBaseParams, SupportersFilterPreset } from '@typings';
import { ISupporter } from '@typings/api/Supporters';

interface ISupportersParams extends IHookBaseParams {}

const PAGE_SIZE = 20;

export const useSupporters = (params: ISupportersParams) => {
    const {
        active,
    } = params;

    const { currentUser } = useCurrentUser();

    const {
        supporters: {
            useLazyLoadSupportersListQuery,
            useGetSupportedStreamersQuery,
        },
    } = useApi();

    const {
        data: supportedStreamers,
        isFetching: isSupportedStreamerFetching,
        isUninitialized: isSupportedStreamerUninitialized,
    } = useGetSupportedStreamersQuery(void(0), { skip: !currentUser });
    const [loadSupportersPage, { isFetching: isSupportersFetching }] = useLazyLoadSupportersListQuery();

    const [supporters, setSupporters] = useState<Array<ISupporter>>();
    const [supportersCount, setSupportersCount] = useState(0);
    const [page, setPage] = useState(1);
    const [pagesCount, setPagesCount] = useState(0);
    const [query, setQuery] = useState('');
    const [filters, setFilters] = useState<Array<SupportersFilterPreset>>([]);

    const changePage = (val: number) => setPage(val);

    const setSearchQuery = (val: string) => {
        if (val !== query) setQuery(val);
    };

    const changeFilters = (val: Array<SupportersFilterPreset>) => {
        setFilters(val);
    };

    const switchSupporterMute = (supporterId: string) => {
        setSupporters(supporters?.map((s) => s.supporterId === supporterId
            ? { ...s, mutedByStreamer: !s.mutedByStreamer }
            : s,
        ));
    };

    useEffect(() => {
        if (page > 0 && active) {
            loadSupportersPage({
                limit: PAGE_SIZE,
                skip: (page - 1) * PAGE_SIZE,
                query,
                filters,
            }, false).unwrap().then((loadedSupportersPage) => {
                setSupporters(loadedSupportersPage.data);
                setSupportersCount(loadedSupportersPage.total);
                setPagesCount(Math.ceil(loadedSupportersPage.total / PAGE_SIZE));
            });
        }
    }, [page, query, active, filters]);

    return {
        isSupportersFetching,
        isSupportedStreamerFetching,
        isSupportedStreamerUninitialized,
        filters,
        page,
        pagesCount,
        searchQuery: query,
        supportedStreamers: supportedStreamers?.data,
        supportedStreamersCount: supportedStreamers?.total,
        supporters,
        supportersCount,
        changePage,
        setSearchQuery,
        changeFilters,
        switchSupporterMute,
    };
};
