import { ETheme } from '@hyperclap/ui';
import { noop } from '@hyperclap/utils';
import cn from 'classnames';
import React, { useEffect, useRef } from 'react';

import { useOneShot } from '@hooks';
import { METRIC_STICKER_SEND_POPUP_OPENED, useEvents } from '@hooks/app/events';
import { ISticker } from '@typings';

import {
    Bottom,
    Content,
    Top,
} from './components';
import s from './StickerPopup.scss';
import { IStickerPopupDataExtended } from './types';


interface IStickerPopupProps extends IStickerPopupDataExtended {
    sticker?: ISticker;
    updateSticker(stickerData: ISticker): void;
    closeModal(): void;
    onShowBuyCurrencyPopup(): void;
    onCloseBuyCurrencyPopup(): void;
}

export const StickerPopup = (props: IStickerPopupProps) => {
    const {
        sticker,
        closeModal,
        onClose = noop,
        goToNextSticker = noop,
        goToPrevSticker = noop,
    } = props;
    const stickerRef = useRef(sticker);
    const extendedProps = {
        ...props,
        sticker,
        closeModal,
    } as IStickerPopupProps & { sticker: ISticker; };

    const {
        registerFrontendEvent,
    } = useEvents({});

    const handleWindowKeyDown = (event: KeyboardEvent) => {
        /* eslint-disable sonarjs/no-duplicated-branches */
        switch (event.key) {
            case 'Escape': {
                closeModal();
                onClose();

                break;
            }

            case 'ArrowRight': {
                event.preventDefault();
                goToNextSticker(stickerRef.current);

                break;
            }

            case 'ArrowDown': {
                event.preventDefault();
                goToNextSticker(stickerRef.current);

                break;
            }

            case 'ArrowLeft': {
                event.preventDefault();
                goToPrevSticker(stickerRef.current);

                break;
            }

            case 'ArrowUp': {
                event.preventDefault();
                goToPrevSticker(stickerRef.current);

                break;
            }
        }
        /* eslint-enable sonarjs/no-duplicated-branches */
    };

    const handleNextClick = () => goToNextSticker(sticker);

    const handlePrevClick = () => goToPrevSticker(sticker);

    useOneShot({
        fn: () => registerFrontendEvent({
            eventName: METRIC_STICKER_SEND_POPUP_OPENED,
            data: {
                labels: { device: 'desktop' },
            },
        }),
    });

    useEffect(() => {
        window.addEventListener('keydown', handleWindowKeyDown);

        return () => window.removeEventListener('keydown', handleWindowKeyDown);
    }, []);

    useEffect(() => {
        stickerRef.current = sticker;
    }, [sticker]);

    return (
        <div className={cn(s.root, `theme-${ETheme.DARK}`)}>
            {sticker && (
                <>
                    <Top {...extendedProps}/>
                    <Content
                        {...extendedProps}
                        onNextClick={handleNextClick}
                        onPrevClick={handlePrevClick}
                    />
                    <Bottom {...extendedProps}/>
                </>
            )}
        </div>
    );
};
