import React from 'react';

import { EventKind, IStickerRelatedEvent, IUser, TMemeAlertsEvent } from '@typings';

import { EventBonus } from './EventBonus';
import { EventBuyCurrency } from './EventBuyCurrency';
import { EventGeneric } from './EventGeneric';
import { EventModerationStatus } from './EventModerationStatus';
import { EventStickerSent } from './EventStickerSent';
import { EventWelcomeBonusEarned } from './EventWelcomeBonusEarned';

interface IEventItemProps {
    currentUser?: IUser;
    event: TMemeAlertsEvent;
    onReplayClick(event: TMemeAlertsEvent): void;
    onToggleDisableStateClick(event: TMemeAlertsEvent): void;
    onToggleMuteStateClick(event: TMemeAlertsEvent): void;
    onApproveClick(event: IStickerRelatedEvent): void;
    onDeclineClick(event: IStickerRelatedEvent): void;
    animated?: boolean;
}

export const EventItem = (props: IEventItemProps) => {
    const {
        currentUser,
        event,
        animated,
        onReplayClick,
        onToggleDisableStateClick,
        onToggleMuteStateClick,
        onApproveClick,
        onDeclineClick,
    } = props;

    switch (event.kind) {
        case EventKind.STICKER_SENT:
        case EventKind.MEME_CANNON_STICKER_SENT:
            return (
                <EventStickerSent
                    currentUser={currentUser}
                    animated={animated}
                    event={event}
                    onReplayClick={onReplayClick}
                    onToggleDisableStateClick={onToggleDisableStateClick}
                    onToggleMuteStateClick={onToggleMuteStateClick}
                    onApproveClick={onApproveClick}
                    onDeclineClick={onDeclineClick}
                />
            );
        case EventKind.BUY_CURRENCY:
            return (<EventBuyCurrency event={event} animated={animated} />);
        case EventKind.MODERATION_STATUS:
            return (<EventModerationStatus event={event} animated={animated} />);
        case EventKind.WELCOME_BONUS_EARNED:
            return (<EventWelcomeBonusEarned event={event} animated={animated} currentUser={currentUser} />);
        case EventKind.BONUS_EARNED:
        case EventKind.BONUS_ISSUED:
            return (<EventBonus event={event} animated={animated} currentUser={currentUser} />);
        case EventKind.GENERIC:
            return (<EventGeneric event={event} animated={animated} />);
    }
};
