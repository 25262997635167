import { ETheme, ThemeContext } from '@hyperclap/ui';
import React, { useContext, useLayoutEffect } from 'react';

import { TApp } from '@hooks';
import { MobilePage } from '@views';

import { Header } from './components';


interface IStreamerAreaPage extends TApp {}

export const StreamerAreaPageMobile = (props: React.PropsWithChildren<IStreamerAreaPage>) => {
    const { changeTheme } = useContext(ThemeContext);

    const {
        children,
        streamer: {
            streamer,
        },
        stickers: {
            selectedSticker,
        },
    } = props;

    useLayoutEffect(() => {
        changeTheme(ETheme.LIGHT, true);
    }, []);

    return (
        <MobilePage
            header={<Header {...props} />}
            pageBackgroundUrl={streamer?.channel.backgroundUrl}
            pageBackgroundMode={streamer?.channel.backgroundMode}
            preventScroll={!!selectedSticker}
        >
            {children}
        </MobilePage>
    );
};
