// noinspection JSUnusedGlobalSymbols
import { Period } from '../common';
import { IVoiceDescriptor } from '../tts';

/** Types of system events */
export enum EventKind {
    /** Generic event */
    GENERIC = 'generic',

    /** User registration event of a new user */
    REGISTRATION = 'registration',

    /** User login event of an existing user */
    LOGIN = 'login',

    /** Streamer's page is opened (somebody opened streamer's channel page */
    STREAMER_PAGE_OPEN = 'page-open',

    /** Somebody opened send sticker popup (not send sticker yet) */
    SEND_STICKER_POPUP_OPEN = 'send-sticker-popup-open',

    /** Somebody opened payment page (not paid yet) */
    PAYMENT_DIALOG_OPEN = 'payment-dialog-open',

    /** Event about sent sticker */
    STICKER_SENT = 'sticker-sent',

    /** Somebody bought currency */
    BUY_CURRENCY = 'buy-currency',

    /** Sticker was moderated */
    MODERATION_STATUS = 'moderation-status',

    /** Viewer earned welcome bonus (this event is sent to the streamer) */
    WELCOME_BONUS_EARNED = 'welcome-bonus-earned',

    /** Viewer earned first payment bonus or a streamer gave a bonus to the viewer (this event is sent to the streamer) */
    BONUS_EARNED = 'bonus-earned',

    /** Viewer earned first payment bonus or a streamer gave a bonus to the viewer (this event is sent to the viewer) */
    BONUS_ISSUED = 'bonus-issued',

    /** Somebody sent meme cannon sticker (multiple stickers at once) */
    MEME_CANNON_STICKER_SENT = 'meme-cannon-sticker-sent',
}

// noinspection JSUnusedGlobalSymbols
export enum ModerationState {
    MODERATION = 'MODERATION',
    APPROVED = 'APPROVED',
    DECLINED = 'DECLINED',
    DELETED = 'DELETED',
}
// noinspection JSUnusedGlobalSymbols
/** How was the bonus received? (issued manually by the streamer, earned for the first payment) */
export enum BonusType {
    MANUAL,
    FIRST_PAYMENT,
}

/** Generic event, parent interface for all types of events */
export interface IEvent {
    _id: string; // TODO: _id should not be used as ID, remove it in the ideal case
    id: string;
    timestamp: number;
    kind: EventKind;
}

interface IUserActionEvent extends IEvent {
    userId: string;
    userAlias: string;
    userName: string;
    userAvatar: string;
    streamerName: string;
    streamerId: string;
    isUserMutedByStreamer: boolean;
}

export interface IStickerRelatedEvent extends IUserActionEvent {
    stickerId: string;
    stickerName: string;
    paidPrice: number;
    message?: string;
    isVoiceUsed?: boolean;
    isStickerDisabled?: boolean;
    isPreModerationNeeded?: boolean;
    preModerationResult?: boolean;
    userVoice?: Pick<IVoiceDescriptor, 'voice' | 'provider'>;
}

interface IBonusRelatedEvent extends IUserActionEvent {
    bonusValue: number;
    isManual: boolean;
    bonusType: BonusType;
}

export interface IGenericEvent extends IEvent {
    kind: EventKind.GENERIC;
    title?: string;
    message?: string;
    data?: object;
}

export interface IStickerSentEvent extends IStickerRelatedEvent {
    kind: EventKind.STICKER_SENT;
    fullscreen?: boolean;
}

export interface IMemeCannonStickerSentEvent extends IStickerRelatedEvent {
    kind: EventKind.MEME_CANNON_STICKER_SENT;
    count: number;
}

export interface IBuyCurrencyEvent extends IUserActionEvent {
    kind: EventKind.BUY_CURRENCY;
    packValue: number;
    packPrice: number;
    packDescription: string;
    preventStreamerNotification?: boolean;
    firstPayment?: boolean;
}

export interface IModerationStatusEvent extends IEvent {
    kind: EventKind.MODERATION_STATUS;
    creatorId: string;
    moderatorId: string;
    stickerId: string;
    stickerName: string;
    status: ModerationState;
    reason?: string;
}

export interface IWelcomeBonusEarnedEvent extends IBonusRelatedEvent {
    kind: EventKind.WELCOME_BONUS_EARNED;
}

export interface IBonusEarnedEvent extends IBonusRelatedEvent {
    kind: EventKind.BONUS_EARNED;
}

export interface IBonusIssuedEvent extends IBonusRelatedEvent {
    kind: EventKind.BONUS_ISSUED;
}

export type TMemeAlertsEvent =
    IGenericEvent |
    IStickerSentEvent |
    IMemeCannonStickerSentEvent |
    IBuyCurrencyEvent |
    IModerationStatusEvent |
    IWelcomeBonusEarnedEvent |
    IBonusEarnedEvent |
    IBonusIssuedEvent;

// =======================================================================================================
// TODO: All types, interfaces and enums above should be reviewed and replaced by the unified interface */

export enum EventsFilterPreset {
    PURCHASES,
    BONUSES,
    STICKERS,
    MEME_CANNON,
    FULLSCREEN,
    MODERATION,
    NOTIFICATIONS,
}

export interface IEventsFilters {
    period?: Period;
    filters?: Array<EventsFilterPreset>;
    date?: number;
}

export interface ISupporterEventsFilters {
    supporterId: string;
    filters?: Array<EventsFilterPreset>;
}

export interface IEventData {
    /** Unique ID */
    _id: string;

    /** Unique ID */
    id: string;

    /** Timestamp of an event */
    timestamp: number;

    /** Event kind */
    kind: EventKind;

    /** Value of streamer's currency purchased/spent if it's applicable for the event kind */
    coins?: number;

    /** True if the event is about sticker sending and sticker is the fullscreen one */
    fullscreen?: boolean;

    /** True if the event is about meme cannon sending */
    memeCannon?: boolean;

    /** Count of meme count stickers */
    memeCannonCount?: number;

    /** Sticker name for sending events */
    stickerName?: string;

    /** ID of the user who is reason of the event */
    userId?: string;

    /** Name of the user who is reason of the event */
    userName?: string;

    /** Name of the user's channel who is reason of the event */
    userLink?: string;

    /** Message to a streamer for sending events */
    message?: string;

    /** Moderation state for moderation events */
    status?: ModerationState;

    /** Reason of moderation status if it's applicable */
    reason?: string;

    /** Paid price when coin pack was purchased */
    packPrice?: number;

    /** Type of the bonus */
    bonusType?: BonusType;
}

// =======================================================================================================
// Client-side events registration interfaces.
// They are used for events which could not be registered on the server

export interface ISendStickerDialogOpenedEventRegistrationParams {
    userId?: string;
    streamerName: string;
    streamerId: string;
    stickerId: string;
}

export interface IStreamerPageOpenEventRegistrationParams {
    userId: string;
    streamerName: string;
    streamerId: string;
}

export interface IRegisterEventDto {
    value?: number;
    labels?: Partial<Record<string, string | number>>;
}
