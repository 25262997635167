import React from 'react';

import { DogLoader } from '@views';

import s from './LoaderPage.scss';


export const LoaderPage = () => {
    return (
        <div className={s.container}>
            <DogLoader size={200}/>
        </div>
    );
};
