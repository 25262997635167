import {
    Button,
    Column,
    EComponentColor,
    EComponentSize,
    ETooltipPosition,
    Hint,
    Label,
    Row,
    Select,
    Switch,
    TextArea,
    Tooltip,
} from '@hyperclap/ui';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import {
    IconCopyrightSquare,
    IconEtmaASignSquare,
    IconEtmaESignSquare,
    IconEtmaMSignSquare,
    IconEtmaTSignSquare,
    IconTwitchSafeSquare,
    IconTwitchUnsafeLine,
} from '@assets/images/svg';
import { castToArray } from '@common';
import { useAppContext } from '@hooks';
import { ContentMarkAction, EtmaRating, IContentMark, ITag, PreModerationType } from '@typings';
import { TagSelect } from '@views/fragments';
import { ITabParams, Tabs } from '@views/pages';

import s from './ModerationSettings.scss';

enum SettingsMode {
    BANNED_TOPICS = 0,
    SWEAR_FILTER = 1,
}

export const ModerationSettings = () => {
    const app = useAppContext();
    const {
        currentUser: {
            currentUser,
        },
        contentMarks: {
            contentMarks,
            contentMarksByCategoryWithoutBanned,
        },
        settings: {
            streamer: {
                page: {
                    updateChannel,
                },
            },
        },
        translation: {
            t,
        },
    } = app;

    const [settingsMode, setSettingsMode] = useState<SettingsMode>(SettingsMode.BANNED_TOPICS);

    const [etmaRating, setEtmaRating] = useState<EtmaRating>();
    const [isTwitchSafeOnlyEnabled, setTwitchSafeOnlyEnabled] = useState<boolean>();
    const [isCopyrightOnlyEnabled, setCopyrightOnlyEnabled] = useState<boolean>();

    const [isAntiSwearEnabled, setIsAntiSwearEnabled] = useState<boolean>();
    const [antiSwearBadWords, setAntiSwearBadWords] = useState<string>();
    const [antiSwearRemoveLinks, setAntiSwearRemoveLinks] = useState<boolean>();

    const [currentContentMarks, setCurrentContentMarks] = useState<Array<IContentMark>>([]);
    const [currentContentMarkIds, setCurrentContentMarkIds] = useState<string[]>([]);

    const [forbiddenTags, setForbiddenTags] = useState<ITag[]>();
    const [preModerationType, setPreModerationType] = useState<PreModerationType>(PreModerationType.DISABLED);

    const PREMODERATION_TYPES = [
        {
            caption: t('streamer-settings.moderation.pre-moderation.options.disabled'),
            value: PreModerationType.DISABLED,
            selected: preModerationType === PreModerationType.DISABLED,
        },
        {
            caption: t('streamer-settings.moderation.pre-moderation.options.pre-moderation'),
            value: PreModerationType.PREMODERATION,
            selected: preModerationType === PreModerationType.PREMODERATION,
        },
        {
            caption: t('streamer-settings.moderation.pre-moderation.options.decline-unsafe'),
            value: PreModerationType.REJECT_UNSAFE,
            selected: preModerationType === PreModerationType.REJECT_UNSAFE,
        },
    ];

    const onSave = () => {
        const twitchSafeOnlyEnabled = !isTwitchSafeOnlyEnabled ? checkTwitchSafeMarks(currentContentMarks) : isTwitchSafeOnlyEnabled;
        void updateChannel({
            etmaRating,
            forbiddenTags,
            isAntiSwearEnabled,
            antiSwearRemoveLinks,
            antiSwearBadWords,
            onlyTwitchFriendly: twitchSafeOnlyEnabled,
            isCopyrightFreeOnly: isCopyrightOnlyEnabled,
            forbiddenContentMarks: currentContentMarkIds,
            preModerationType,
        });
    };

    const onContentMarkSelect = (contentMark: IContentMark) => {
        if (currentContentMarkIds.includes(contentMark.id ?? '')) {
            if (contentMark.actions?.includes(ContentMarkAction.TWITCH_UNSAFE)) {
                setTwitchSafeOnlyEnabled(false);
            }

            setCurrentContentMarkIds(currentContentMarkIds.filter((id) => id !== contentMark.id));
            setCurrentContentMarks(currentContentMarks.filter((item) => item.id !== contentMark.id));
        } else {
            setCurrentContentMarkIds([...currentContentMarkIds, contentMark.id ?? '']);
            setCurrentContentMarks([...currentContentMarks, contentMark]);
        }
    };

    const setTwitchContentMarks = () => {
        if (contentMarks) {
            const twitchUnsafeMarks = contentMarks.filter((mark) => mark.actions?.includes(ContentMarkAction.TWITCH_UNSAFE));

            const Ids = [...currentContentMarkIds];
            const marks = [...currentContentMarks];

            for (const mark of twitchUnsafeMarks) {
                if (!currentContentMarkIds.includes(mark.id ?? '')) {
                    Ids.push(mark.id);
                    marks.push(mark);
                }
            }

            setCurrentContentMarkIds([...currentContentMarkIds, ...Ids]);
            setCurrentContentMarks([...currentContentMarks, ...marks]);
        }
    };

    const onSetEtma = (newEtma: EtmaRating) => {
        if (contentMarks && etmaRating !== undefined) {
            const etmaMarks = contentMarks.filter((mark)=>
                mark.actions?.includes(ContentMarkAction.ETMA) && mark.etmaRating && !mark.category.includes('Banned'));

            if (newEtma > etmaRating) {
                const allowedMarks = currentContentMarks.filter((mark) =>
                    (mark.actions?.includes(ContentMarkAction.ETMA) && mark.etmaRating && mark.etmaRating > newEtma) ||
                    !mark.etmaRating || (isTwitchSafeOnlyEnabled && mark.actions?.includes(ContentMarkAction.TWITCH_UNSAFE)));

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                const enabledMarks = etmaMarks.filter((mark) => mark.etmaRating > newEtma &&
                    !allowedMarks.includes(mark));

                const concat = [...allowedMarks, ...enabledMarks];

                setCurrentContentMarkIds(concat.map((mark) => mark.id));
                setCurrentContentMarks(concat);
            }

            if (newEtma < etmaRating) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                const enabledMarks = etmaMarks.filter((mark) => mark.etmaRating > newEtma && !currentContentMarkIds.includes(mark.id));

                setCurrentContentMarkIds([...currentContentMarkIds, ...enabledMarks.map((mark) => mark.id)]);
                setCurrentContentMarks([...currentContentMarks, ...enabledMarks]);
            }
        }

        setEtmaRating(newEtma);
    };

    const checkTwitchSafeMarks = (marks: IContentMark[]) => {
        if (!isTwitchSafeOnlyEnabled) {
            const twitchUnsafeMarks = contentMarks?.filter((mark) => mark.actions?.includes(ContentMarkAction.TWITCH_UNSAFE));

            if (twitchUnsafeMarks?.every((mark) => marks.includes(mark))) {
                setTwitchSafeOnlyEnabled(true);

                return true;
            }
        }

        return false;
    };

    const translateCategory = (category: string) => {
        return t(`streamer-settings.moderation.marks.categories.${category.toLowerCase().replace(/ /g, '-')}`);
    };

    const translateTooltip = (mark: IContentMark) => {
        let text = t(`streamer-settings.moderation.marks.tooltips.${mark.name}`, '');

        if (text) {
            const action = currentContentMarkIds.includes(mark.id ?? '')
                ? t('streamer-settings.moderation.marks.tooltips.banned')
                : t('streamer-settings.moderation.marks.tooltips.allowed');
            text += ` - ${action}`;
        }

        return text;
    };

    const translateMarkName = (name: string) => {
        return t(`streamer-settings.moderation.marks.names.${name}`);
    };

    const TABS: Array<ITabParams<SettingsMode>> = [
        { caption: t('streamer-settings.moderation.banned-tab-label'), tag: SettingsMode.BANNED_TOPICS },
        { caption: t('streamer-settings.moderation.swear-tab-label'), tag: SettingsMode.SWEAR_FILTER },
    ];

    const onTabSelected = (tab: SettingsMode = SettingsMode.BANNED_TOPICS) => {
        setSettingsMode(tab);
    };

    useEffect(() => {
        if (isTwitchSafeOnlyEnabled) {
            setTwitchContentMarks();
        }
    }, [isTwitchSafeOnlyEnabled]);

    useEffect(() => {
        checkTwitchSafeMarks(currentContentMarks);
    }, [currentContentMarks]);

    useEffect(() => {
        if (currentUser?.channel) {
            setEtmaRating(currentUser.channel?.etmaRating);
            setTwitchSafeOnlyEnabled(currentUser.channel?.onlyTwitchFriendly);
            setCopyrightOnlyEnabled(currentUser.channel?.isCopyrightFreeOnly);
            setForbiddenTags(currentUser.channel?.forbiddenTags ?? []);
            setIsAntiSwearEnabled(currentUser?.channel?.isAntiSwearEnabled);
            setAntiSwearRemoveLinks(currentUser?.channel?.antiSwearRemoveLinks);
            setAntiSwearBadWords(currentUser?.channel?.antiSwearBadWords);
            setPreModerationType(currentUser?.channel?.preModerationType ?? PreModerationType.DISABLED);
        }
    }, [currentUser]);

    useEffect(() => {
        if (contentMarks) {
            if (isTwitchSafeOnlyEnabled) {
                setTwitchContentMarks();
            }

            if (currentUser?.channel.forbiddenContentMarks && currentUser.channel.forbiddenContentMarks.length > 0) {
                const userMarks = contentMarks.filter((mark) =>
                    currentUser.channel.forbiddenContentMarks.includes(mark.id));

                setCurrentContentMarkIds(userMarks.map((mark) => mark.id));
                setCurrentContentMarks(userMarks);
                checkTwitchSafeMarks(userMarks);
            }
        }
    }, [contentMarks]);

    return (
        <Column rowGap={10}>
            <Row padding={{ top: 15 }}>
                <Column grow>
                    <Tabs activeTab={settingsMode} onSelectionChange={onTabSelected} tabs={TABS}/>
                </Column>
            </Row>
            <Row padding={{ bottom: 50 }}>
                <Column grow width={480} rowGap={10}>
                    {settingsMode === SettingsMode.BANNED_TOPICS &&
                        <Column rowGap={20}>
                            <Row style={{ alignItems: 'flex-start' }} columnGap={50}>
                                <Column rowGap={20}>
                                    <Row columnGap={18}>
                                        <Column rowGap={10}>
                                            <Label
                                                padding={{ bottom: 0, top: 0 }}
                                                caption={t('streamer-settings.moderation.etma-rating-label')}
                                                size={EComponentSize.SMALL}
                                            />
                                            <Row>
                                                <Tooltip content={t('streamer-settings.moderation.etma.e')} position={ETooltipPosition.TOP}>
                                                    <div
                                                        className={cn(s.etmaButton, { [s.selectedE]: etmaRating === EtmaRating.E })}
                                                        onClick={() => onSetEtma(EtmaRating.E)}>
                                                        <IconEtmaESignSquare/>
                                                    </div>
                                                </Tooltip>
                                                <Tooltip content={t('streamer-settings.moderation.etma.t')} position={ETooltipPosition.TOP}>
                                                    <div
                                                        className={cn(s.etmaButton, { [s.selectedT]: etmaRating === EtmaRating.T })}
                                                        onClick={() => onSetEtma(EtmaRating.T)}>
                                                        <IconEtmaTSignSquare/>
                                                    </div>
                                                </Tooltip>
                                                <Tooltip content={t('streamer-settings.moderation.etma.m')} position={ETooltipPosition.TOP}>
                                                    <div
                                                        className={cn(s.etmaButton, { [s.selectedM]: etmaRating === EtmaRating.M })}
                                                        onClick={() => onSetEtma(EtmaRating.M)}>
                                                        <IconEtmaMSignSquare/>
                                                    </div>
                                                </Tooltip>
                                                <Tooltip content={t('streamer-settings.moderation.etma.a')} position={ETooltipPosition.TOP}>
                                                    <div
                                                        className={cn(s.etmaButton, { [s.selectedA]: etmaRating === EtmaRating.A })}
                                                        onClick={() => onSetEtma(EtmaRating.A)}>
                                                        <IconEtmaASignSquare/>
                                                    </div>
                                                </Tooltip>
                                            </Row>
                                        </Column>
                                        <Column rowGap={10}>
                                            <Label
                                                padding={{ bottom: 0, top: 0 }}
                                                caption={t('streamer-settings.moderation.twitch-safe-label')}
                                                size={EComponentSize.SMALL}
                                            />
                                            <Row>
                                                <Tooltip
                                                    content={t('streamer-settings.moderation.twitch-safe-tooltip')}
                                                    position={ETooltipPosition.TOP}>
                                                    <div
                                                        className={cn(s.twitchSafeButton, {
                                                            [s.twitchSafeButtonActive]: isTwitchSafeOnlyEnabled,
                                                        })}
                                                        onClick={() => setTwitchSafeOnlyEnabled(!isTwitchSafeOnlyEnabled)}>
                                                        <IconTwitchSafeSquare/>
                                                    </div>
                                                </Tooltip>
                                            </Row>
                                        </Column>
                                        <Column rowGap={10}>
                                            <Label
                                                padding={{ bottom: 0, top: 0 }}
                                                caption={t('streamer-settings.moderation.copyright-label')}
                                                size={EComponentSize.SMALL}
                                            />
                                            <Row>
                                                <Tooltip
                                                    content={t('streamer-settings.moderation.copyright-tooltip')}
                                                    position={ETooltipPosition.TOP}
                                                >
                                                    <div
                                                        className={cn(s.copyrightButton, { [s.copyrightButtonActive]: isCopyrightOnlyEnabled })}
                                                        onClick={() => setCopyrightOnlyEnabled(!isCopyrightOnlyEnabled)}>
                                                        <IconCopyrightSquare/>
                                                    </div>
                                                </Tooltip>
                                            </Row>
                                        </Column>
                                    </Row>

                                    {contentMarksByCategoryWithoutBanned && Object.keys(contentMarksByCategoryWithoutBanned).map((category) => (
                                        <Row key={category}>
                                            <Column width={480} rowGap={10}>
                                                <Row>
                                                    <Label
                                                        padding={{ top: 0, bottom: 0 }}
                                                        caption={translateCategory(category)}
                                                        size={EComponentSize.SMALL}
                                                    />
                                                </Row>
                                                <Row>
                                                    <div className={s.marks}>
                                                        {contentMarksByCategoryWithoutBanned[category].map((mark) => (
                                                            <Tooltip
                                                                key={mark.id}
                                                                position={ETooltipPosition.TOP}
                                                                content={translateTooltip(mark)}
                                                            >
                                                                <div
                                                                    onClick={() => onContentMarkSelect(mark)}
                                                                    className={cn(s.item, {
                                                                        [s.common]: !mark.actions?.includes(ContentMarkAction.REJECT),
                                                                        [s.red]: mark.actions?.includes(ContentMarkAction.REJECT),
                                                                        [s.selected]: currentContentMarkIds.includes(mark.id ?? ''),
                                                                    })}>
                                                                    <div className={s.itemText}>
                                                                        {mark.actions?.includes(ContentMarkAction.TWITCH_UNSAFE) &&
                                                                            <div className={s.twitchIcon}>
                                                                                <IconTwitchUnsafeLine/>
                                                                            </div>
                                                                        }
                                                                        {translateMarkName(mark.name)}
                                                                    </div>
                                                                </div>
                                                            </Tooltip>
                                                        ))}
                                                    </div>
                                                </Row>
                                            </Column>
                                        </Row>
                                    ))}
                                </Column>
                                <Column>
                                    <Hint grow muted textSize={EComponentSize.SMALL}>
                                        <div>
                                            {castToArray<string>(
                                                t('streamer-settings.moderation.banned.settings-advices-first-part', { returnObjects: true }))
                                                .map((element, idx) => (
                                                    <div key={idx} className={cn({
                                                        [s.bold]: element.includes('‍'),
                                                    })}>
                                                        <Trans i18nKey={element} components={[<a key={idx}/>]}>
                                                            {element}
                                                        </Trans>
                                                    </div>
                                                ))
                                            }
                                            <div className={s.marks} style={{ paddingTop: 10 }}>
                                                <div
                                                    className={cn(s.item, s.common)}>
                                                    <div className={s.itemText}>
                                                        {t('streamer-settings.moderation.banned.settings-advices-allowed-mark')}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={s.marks} style={{ paddingTop: 10, paddingBottom: 20 }}>
                                                <div
                                                    className={cn(s.item, s.common, s.selected)}>
                                                    <div className={s.itemText}>
                                                        {t('streamer-settings.moderation.banned.settings-advices-banned-mark')}
                                                    </div>
                                                </div>
                                            </div>
                                            {castToArray<string>(
                                                t('streamer-settings.moderation.banned.settings-advices-second-part', { returnObjects: true }))
                                                .map((element, idx) => (
                                                    <div key={idx} className={cn({
                                                        [s.bold]: element.includes('‍'),
                                                    })}>
                                                        <Trans i18nKey={element} components={[<a key={idx}/>]}>
                                                            {element}
                                                        </Trans>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </Hint>
                                </Column>
                            </Row>
                            <Row style={{ alignItems: 'flex-start' }} columnGap={50}>
                                <Column>
                                    <Row>
                                        <Column width={480}>
                                            <Label
                                                padding={{ bottom: 10, top: 0 }}
                                                caption={t('streamer-settings.moderation.banned-tags')}
                                                size={EComponentSize.SMALL}
                                            />
                                            <TagSelect
                                                placeholder={t('streamer-settings.moderation.add-tags')}
                                                defaultValue={forbiddenTags}
                                                tagClassName={s.bannedTag}
                                                debounceTime={500}
                                                maxLength={20}
                                                onTagsChanged={setForbiddenTags}
                                            />
                                        </Column>
                                    </Row>

                                    <Row margin={{ top: 20 }}>
                                        <Column>
                                            <Select
                                                caption={t('streamer-settings.moderation.pre-moderation.select-title')}
                                                options={PREMODERATION_TYPES}
                                                width={300}
                                                onSelectionChanged={(val) => setPreModerationType(val?.value as PreModerationType)}
                                            />
                                            <Hint muted margin={{ top: 5 }} textSize={EComponentSize.SMALL}>
                                                {t(`streamer-settings.moderation.pre-moderation.hints.${preModerationType}`)}
                                            </Hint>
                                        </Column>
                                    </Row>


                                    <Row margin={{ top: 20 }}>
                                        <Button
                                            caption={t('common.save')}
                                            color={EComponentColor.ACCENT}
                                            width={160}
                                            onClick={onSave}
                                        />
                                    </Row>
                                </Column>
                                <Column grow>
                                    <Hint grow muted textSize={EComponentSize.SMALL}>
                                        <div>
                                            {castToArray<string>(
                                                t('streamer-settings.moderation.banned.settings-advices-banned-tags-part',
                                                    { returnObjects: true }))
                                                .map((element, idx) => (
                                                    <div key={idx} className={cn({
                                                        [s.bold]: element.includes('‍'),
                                                    })}>
                                                        <Trans i18nKey={element} components={[<a key={idx}/>]}>
                                                            {element}
                                                        </Trans>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </Hint>
                                </Column>
                            </Row>
                        </Column>
                    }
                    {settingsMode === SettingsMode.SWEAR_FILTER &&
                        <Column grow rowGap={10}>
                            <Row style={{ marginTop: -10 }}>
                                <Label caption={t('streamer-settings.moderation.anti-swear')} width={150}
                                    size={EComponentSize.MEDIUM}/>
                                <Switch
                                    checked={isAntiSwearEnabled}
                                    color={EComponentColor.ACCENT}
                                    onChange={setIsAntiSwearEnabled}
                                />
                            </Row>
                            <Hint muted textSize={EComponentSize.SMALL}>
                                {t('streamer-settings.moderation.anti-swear-description')}
                            </Hint>
                            <Row margin={{ top: 5 }}>
                                <Label caption={t('streamer-settings.moderation.delete-links')} width={150}
                                    size={EComponentSize.MEDIUM}/>
                                <Switch
                                    checked={antiSwearRemoveLinks}
                                    color={EComponentColor.ACCENT}
                                    onChange={setAntiSwearRemoveLinks}
                                />
                            </Row>
                            <Hint muted textSize={EComponentSize.SMALL}>
                                {t('streamer-settings.moderation.replace-links')}
                            </Hint>
                            <Row>
                                <Column>
                                    <Label
                                        caption={t('bad-words-popup.bad-words-list')}
                                        style={{ lineHeight: '20px' }}
                                        size={EComponentSize.SMALL}
                                    />
                                    <TextArea
                                        maxRows={10}
                                        defaultValue={antiSwearBadWords}
                                        onChange={(val) => setAntiSwearBadWords(val?.toString() ?? '')}
                                    />
                                </Column>
                            </Row>

                            <Row margin={{ top: 10 }}>
                                <Button
                                    caption={t('common.save')}
                                    color={EComponentColor.ACCENT}
                                    width={160}
                                    onClick={onSave}
                                />
                            </Row>
                        </Column>
                    }
                </Column>
            </Row>
        </Column>
    );
};
