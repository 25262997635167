import {
    Button,
    Column,
    EComponentColor,
    ERowJustification,
    Row,
    useModals,
} from '@hyperclap/ui';
import cn from 'classnames';
// eslint-disable-next-line import/no-unresolved
import { t } from 'i18next';
import React from 'react';
import { Trans } from 'react-i18next';

import { IconCheckCircleGradient } from '@assets/images/svg';
import { castToArray } from '@common';

import s from './SuccessState.scss';

export const SuccessState = () => {
    const { closeModal } = useModals();

    const onHoorayClick = async () => {
        closeModal();
    };

    return (
        <>
            <Row className={cn(s.popupRow, s.description)}>
                <Column>
                    <Row justification={ERowJustification.CENTER}>
                        <IconCheckCircleGradient className={s.iconCheck} style={{ marginBottom: 30 }}/>
                    </Row>
                    <Row>
                        <div className={s.title}>
                            {
                                castToArray<string>(t('user-verification-popup.verification-success-title', { returnObjects: true }))
                                    .map((element, idx) => (
                                        <Trans
                                            key={idx}
                                            i18nKey={element}
                                            components={[<div key={idx} />]}>
                                            {element}
                                        </Trans>
                                    ))
                            }
                        </div>
                    </Row>
                    <Row>
                        <div>
                            {
                                castToArray<string>(t('user-verification-popup.verification-success-text', { returnObjects: true }))
                                    .map((element, idx) => (
                                        <Trans key={idx} i18nKey={element} components={[<strong key={idx} />]}>
                                            {element}
                                        </Trans>
                                    ))
                            }
                        </div>
                    </Row>
                </Column>
            </Row>

            <Row className={cn(s.popupRow, s.button)} style={{ marginTop: 40 }}>
                <Button
                    caption={<div>{t('user-verification-popup.ok-button')}</div>}
                    color={EComponentColor.INFO}
                    onClick={onHoorayClick}
                />
            </Row>
        </>
    );
};
