import {
    Button,
    Column,
    Row,
    useModals,
    EComponentColor,
    EComponentSize,
    Label,
} from '@hyperclap/ui';
import { t } from 'i18next';
import React from 'react';

import { IconCloseLine } from '@assets/images/svg';

import s from './ConfirmationPopup.scss';


interface ConfirmationPopupProps {
    title: string;
    text: string;
    acceptButtonText?: string;
    cancelButtonText?: string;
    onAccept: () => void;
    onCancel?: () => void;
}

export const ConfirmationPopup = (props: ConfirmationPopupProps) => {
    const {
        title,
        text,
        acceptButtonText = t('common.ok'),
        cancelButtonText = t('common.cancel'),
        onAccept,
        onCancel,
    } = props;
    const { closeModal } = useModals();

    const onCancelClick = () => {
        onCancel && onCancel();
        closeModal();
    };

    const onAcceptClick = () => {
        onAccept && onAccept();
    };

    return (
        <Column className={s.confirmationPopup}>
            <Column className={s.confirmationPopupContent}>
                <Label caption={title} size={EComponentSize.LARGE}/>
                <Label
                    className={s.confirmationPopupText}
                    caption={text}
                    style={{ lineHeight: '20px', marginBottom: '10px' }}
                    size={EComponentSize.SMALL}
                />
                <Row className={s.footerRow} columnGap={10}>
                    <Button
                        color={EComponentColor.ACCENT}
                        caption={cancelButtonText}
                        onClick={onCancelClick}
                        width={120}
                    />
                    <Button
                        caption={acceptButtonText}
                        linkStyle={true}
                        onClick={onAcceptClick}
                        width={120}
                    />
                </Row>
            </Column>

            <div className={s.closeButton} onClick={closeModal}>
                <IconCloseLine/>
            </div>
        </Column>
    );
};
