import { ReactElement } from 'react';

import { IStickerSentEvent, TMemeAlertsEvent } from '@typings';

/* Parses a boolean value from common string logical values, it is used when reading ENV vars for example. */
export const parseBool = (value: string) => {
    return /(true|1|enabled|yes|ok)/i.test(value?.toLowerCase());
};

export function isReactElement(val: ReactElement | string): val is ReactElement {
    return (val as ReactElement).props !== undefined;
}

export function isStickerSentEvent(event: TMemeAlertsEvent) : event is IStickerSentEvent {
    return (event as IStickerSentEvent).stickerId !== undefined;
}

export const isUndefined = (val: unknown) => val === undefined;
export const isNotUndefined = (val: unknown) => !isUndefined(val);

export const interpolate = (tpl: string, data: object, unknownPlaceholder: string = '') => {
    return tpl.replace(/\{([^}]+)?}/g, (_: unknown, $2: string) => {
        return $2 in data ? data[$2 as keyof object] : unknownPlaceholder;
    });
};
