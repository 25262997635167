// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WebApp__webApp--UlQ0L {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/memealerts/views/pages/Landing/layouts/WebApp/WebApp.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;EACA,mBAAA;EACA,uBAAA;EACA,OAAA;EACA,WAAA;AACJ","sourcesContent":[".webApp {\n    display: flex;\n    flex-direction: column;\n    gap: 40px;\n    align-items: center;\n    justify-content: center;\n    flex: 1;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"webApp": `WebApp__webApp--UlQ0L`
};
export default ___CSS_LOADER_EXPORT___;
