import cn from 'classnames';
import React from 'react';

import s from './SearchField.scss';

export interface SearchFieldProps {
    left?: React.ReactNode;
    right?: React.ReactNode;
    gray?: boolean;
}

export const SearchField = (props: React.PropsWithChildren<SearchFieldProps>) => {
    const {
        left,
        right,
        children,
        gray,
    } = props;

    return (
        <label className={cn(s.searchField, { [s.gray]: gray })}>
            {left && <span className={s.left}>{left}</span>}
            {children}
            {right && <span className={s.right}>{right}</span>}
        </label>
    );
};
