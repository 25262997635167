export const METRIC_LOGIN = 'login';
export const METRIC_STICKER_SENT = 'sticker_sent';
export const METRIC_LANDING_OPENED = 'landing_opened';
export const METRIC_LOGIN_POPUP = 'login_popup';
export const METRIC_STREAMER_PAGE_OPENED = 'streamer_page_opened';
export const METRIC_STICKER_SEND_POPUP_OPENED = 'sticker_sent_popup_opened';
export const METRIC_ADD_TO_FAVORITES = 'add_to_favorites';
export const METRIC_SEARCH = 'search';
export const METRIC_PURCHASE_POPUP = 'purchase_popup';
export const METRIC_PURCHASE_ITEM_CLICK = 'purchase_item_click';
export const METRIC_COINS_ADDED = 'coins_added';
export const METRIC_USER_SETTINGS_SAVED = 'users_settings_saved';
export const METRIC_TELEGRAM_NEW_BOT_USER = 'telegram_new_bot_user';
export const METRIC_TELEGRAM_STICKER_SENT = 'telegram_sticker_sent';
export const METRIC_TELEGRAM_STICKER_CREATED = 'telegram_sticker_created';
export const METRIC_TELEGRAM_WEB_APP_STICKER_SENT = 'telegram_web_app_sticker_sent';
export const METRIC_VERIFICATION_EVENT = 'verification_event';
