import { Button, Column, EComponentColor, Row, TextField, useModals } from '@hyperclap/ui';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconCheckLine, IconTelegramFly } from '@assets/images/svg';
import { useCurrentUser, useVerification, useWebSocket } from '@hooks';
import { IUser, TWSMessage, VerificationStatus, WSMessageType } from '@typings';
import { ConfirmationPopup } from '@views';
import { UserVerificationPopup } from '@views/modals/UserVerificationPopup';

import s from './PersonalConfig.scss';

interface PersonalConfigProps {
    currentUser?: IUser;
    onNameChange(value: string): void;
}

export const PersonalConfig = (props: PersonalConfigProps) => {
    const {
        onNameChange,
    } = props;

    const [resetButtonHidden, setResetButtonHidden] = useState(true);

    const { t: translation } = useTranslation();

    const { showModal, closeModal } = useModals();

    const {
        subscribe,
        unsubscribe,
    } = useWebSocket();

    const {
        resetVerification,
    } = useVerification();

    const { currentUser, refetchCurrentUser } = useCurrentUser();
    const t = (id: string) => translation(`PersonalSettings.PersonalConfig.${id}`);

    const onVerifyClick = async () => {
        if (currentUser) {
            showModal(<UserVerificationPopup currentUser={currentUser}/>);
        }
    };

    const onResetClick = async () => {
        showModal(<ConfirmationPopup
            title={translation('confirmation-popup.verification.label')}
            text={translation('confirmation-popup.verification.text')}
            acceptButtonText={translation('confirmation-popup.verification.reset')}
            onAccept={() => {
                void resetVerification();
                closeModal();
            }}
        />);
    };

    const onMessageHandler = (message: TWSMessage) => {
        const { type } = message;

        if (type === WSMessageType.VERIFICATION_STATE) {
            const status = message.data.status;

            switch (status) {
                case VerificationStatus.SUCCESS:
                case VerificationStatus.FAILED:
                case VerificationStatus.DELETED:
                    refetchCurrentUser();
                    break;
            }
        }
    };

    useEffect(() => {
        if (currentUser) {
            subscribe(currentUser.id, onMessageHandler);
        }

        return () => {
            if (currentUser) {
                unsubscribe(currentUser.id, onMessageHandler);
            }
        };
    }, [currentUser]);

    return (
        <div
            onMouseLeave={() => setResetButtonHidden(true)}
            onMouseEnter={() => setResetButtonHidden(false)}>

            <Row className={s.root} columnGap={20}>
                <Column>
                    <TextField
                        caption={t('label')}
                        maxLength={50}
                        defaultValue={currentUser?.name}
                        width={300}
                        onChanged={onNameChange}
                    />
                </Column>
                <Column>
                    {!currentUser?.isUserVerified
                        ? <Button
                            icon={<IconTelegramFly/>}
                            iconClassName={s.buttonIcon}
                            caption={translation('common.verify')}
                            color={EComponentColor.INFO}
                            onClick={onVerifyClick}
                        />
                        : <Row className={s.verified} columnGap={7}>
                            <Column className={s.verifiedIcon}><IconCheckLine/></Column>
                            <Column>
                                <Row className={s.verifiedText}>
                                    {translation('common.verified')}
                                </Row>
                                <Row>
                                    {!resetButtonHidden &&
                                        <Button
                                            className={s.resetButton}
                                            caption={translation('common.reset')}
                                            linkStyle={true}
                                            onClick={onResetClick}
                                        />
                                    }
                                </Row>
                            </Column>
                        </Row>
                    }
                </Column>
            </Row>
        </div>
    );
};
