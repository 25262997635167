import React from 'react';

import { IconExclamationSolid } from '@assets/images/svg';
import { interpolate } from '@common';
import { IGenericEvent } from '@typings';

import { BaseEvent, IEventItemProps } from './EventBase';
import s from './EventBase.scss';

interface IEventGenericProps extends IEventItemProps<IGenericEvent> {}

export const EventGeneric = ({ event, animated }: IEventGenericProps) => {
    const message = event.message || '';
    const title = event.title || '';
    const data = event.data ?? {};

    return (
        <BaseEvent
            animated={animated}
            event={event}
            color={s.gray}
            icon={<IconExclamationSolid />}
            user={''}
            action={interpolate(title, data)}
            subject={''}
            message={interpolate(message, data)}
        />
    );
};
