import cn from 'classnames';
import React, { CSSProperties, forwardRef, HTMLAttributes, useEffect, useState } from 'react';

import { ImageDefaultUser } from '@assets/images/png';

import s from './MobileAvatar.scss';


interface IAvatarProps extends HTMLAttributes<HTMLDivElement> {
    loading?: boolean
    size?: string;
    src?: string;
}

export const MobileAvatar = forwardRef<
    HTMLDivElement,
    React.PropsWithChildren<IAvatarProps>
>((props: IAvatarProps, ref) => {
    const {
        className,
        loading,
        size = '50px',
        style,
        src,
        ...rest
    } = props;
    ref;

    const avatarStyles = {
        ...style,
        '--avatar-size': size,
        '--avatar-src': `url(${src ?? ImageDefaultUser})`,
    } as CSSProperties;

    const isSvg = !!src && /\.svg$/.test(src);

    const [status, setStatus] = useState<boolean | null>(null);
    const avatarClassName = cn(
        s.avatar,
        className,
        {
            [s.vector]: isSvg,
            [s.raster]: !isSvg,
            [s.error]: status === false,
            [s.initial]: status === null,
            [s.loading]: loading,
        },
    );

    useEffect(() => {
        setStatus(null);

        if (src) {
            const img = new Image();
            img.onload = onImageLoadSuccess;
            img.onerror = onImageLoadError;
            img.src = src;
        }
    }, [src]);

    const onImageLoadError = () => {
        setStatus(false);
    };

    const onImageLoadSuccess = () => {
        setStatus(true);
    };

    return (
        <div
            ref={ref}
            className={avatarClassName}
            style={avatarStyles}
            {...rest}
        />
    );
});

MobileAvatar.displayName = 'MobileAvatar';
