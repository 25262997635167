import { IModalContentWithOptions, useLogger, useModals } from '@hyperclap/ui';
import { noop } from '@hyperclap/utils';
import React, { useEffect, useState } from 'react';

import { ISticker } from '@typings';
import { SendPopup } from '@views/fragments';

import s from './useStickerPopup.scss';
import { StickerPopup } from '../../StickerPopup';
import { IStickerPopupDataExtended } from '../../types';


interface IStickerPopupParams extends IStickerPopupDataExtended {}

export const useStickerPopup = (params: IStickerPopupParams) => {
    const {
        currentUser: { currentUser },
        streamer: { streamer },
        balance: { balance },
        stickers: {
            topic,
            memePartySessionState,
        },
        deviceInfo: { isMobileLayoutRecommended },
        onClose = noop,
    } = params;
    const {
        showModal,
        setContent,
        closeModal,
    } = useModals();
    const logger = useLogger({ target: useStickerPopup.name, showTimestamp: true });
    const [sticker, setSticker] = useState<ISticker>();
    const [isOpened, setIsOpened] = useState(false);
    const [isBuyCurrencyPopupOpened, setIsBuyCurrencyPopupOpened] = useState(false);
    const updateSticker = (stickerData: ISticker) => setSticker(stickerData);

    const handleClose = () => {
        setIsOpened(false);
        onClose();
    };

    const handleShowBuyCurrencyPopup = () => {
        logger.debug('showBuyCurrencyPopup');
        setIsBuyCurrencyPopupOpened(true);
    };

    const handleCloseBuyCurrencyPopup = () => {
        logger.debug('closeBuyCurrencyPopup');
        setIsBuyCurrencyPopupOpened(false);
    };

    const content = isMobileLayoutRecommended
        ? (
            <>
                {sticker && (
                    <SendPopup
                        {...params}
                        closeModal={closeModal}
                        onClose={handleClose}
                    />
                )}
            </>
        )
        : (
            <StickerPopup
                {...params}
                sticker={sticker}
                updateSticker={updateSticker}
                closeModal={closeModal}
                onClose={handleClose}
                onShowBuyCurrencyPopup={handleShowBuyCurrencyPopup}
                onCloseBuyCurrencyPopup={handleCloseBuyCurrencyPopup}
            />
        );

    const showStickerPopup = () => {
        const stickerPopup: IModalContentWithOptions<ISticker> = {
            content,
            options: {
                className: s.noBgModal,
                bodyClassName: s.noPaddingModal,
                onShadowClick: () => {
                    setIsOpened(false);
                    onClose();
                },
            },
        };
        showModal(stickerPopup);
        setIsOpened(true);
    };

    const setStickerPopupItem = (stickerItem: ISticker) => setSticker(stickerItem);

    useEffect(
        () => {
            if (sticker && !isBuyCurrencyPopupOpened) {
                setContent(content);
            }
        },
        [
            sticker,
            currentUser,
            balance,
            topic,
            streamer,
            memePartySessionState,
            isBuyCurrencyPopupOpened,
        ],
    );

    return {
        isOpened,
        currentSticker: sticker,
        showStickerPopup,
        setStickerPopupItem,
    };
};
