import { ETheme, ThemeContext } from '@hyperclap/ui';
import React, { useContext, useLayoutEffect } from 'react';
import { useMatch } from 'react-router-dom';

import { TApp } from '@hooks';
import { MobilePage, DesktopPage } from '@views';

import { Header, MobileHeader } from './components';


interface IStreamerAreaPage extends TApp {}

export const StreamerAreaPage = (props: React.PropsWithChildren<IStreamerAreaPage>) => {
    const { changeTheme } = useContext(ThemeContext);

    const {
        children,
        streamer: {
            streamer,
        },
        deviceInfo: {
            isMobileLayoutRecommended,
        },
        stickers: {
            selectedSticker,
        },
    } = props;

    const match = useMatch(`/${streamer?.channel.name}/:topic`);

    useLayoutEffect(() => {
        changeTheme(ETheme.LIGHT, true);
    }, []);

    return (
        isMobileLayoutRecommended

            ? <MobilePage
                header={<MobileHeader
                    {...props}
                    showStreamerAvatar={!!match?.params.topic}
                />}
                pageBackgroundUrl={streamer?.channel.backgroundUrl}
                pageBackgroundMode={streamer?.channel.backgroundMode}
                preventScroll={!!selectedSticker}
            >
                {children}
            </MobilePage>

            : <DesktopPage
                header={<Header
                    {...props}
                    showStreamerAvatar={!!match?.params.topic}
                />}
                pageBackgroundUrl={streamer?.channel.backgroundUrl}
                pageBackgroundMode={streamer?.channel.backgroundMode}
                sidebarWidth={0}
            >
                {children}
            </DesktopPage>
    );
};
