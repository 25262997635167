import { useMatch } from 'react-router-dom';

import { useCurrentUser } from '@hooks';

import { useCatalogue } from './Catalogue';
import { useChannel } from './Channel';
import { useDashboard } from './Dashboard';
import { useFavorites } from './Favorites';
import { useMine } from './Mine';
import { useSearch } from './Search';
import { useStreamers } from './Streamers';
import { useSupporters } from './Supporters';

export const usePersonalArea = () => {
    const { currentUser } = useCurrentUser();
    const hasUser = !!currentUser;

    return {
        catalogue: useCatalogue({ active: !!useMatch('/stickers') && hasUser }),
        channel: useChannel({ active: !!useMatch('/stickers/channel') && hasUser }),
        dashboard: useDashboard({ active: !!useMatch('/dashboard') && hasUser, currentUser }),
        favorites: useFavorites({ active: !!useMatch('/stickers/favorites') && hasUser }),
        mine: useMine({ active: !!useMatch('/stickers/mine') && hasUser }),
        search: useSearch({ active: !!useMatch('/stickers/search') }),
        supporters: useSupporters({ active: !!useMatch('/supporters') && hasUser }),
        streamers: useStreamers({ active: !!useMatch('/dashboard') && hasUser }),
    };
};
