import { EComponentSize, ScrollableArea } from '@hyperclap/ui';
import React from 'react';
import { Link } from 'react-router-dom';

import { Avatar } from '@components';
import { ISupportedStreamer } from '@typings';

import s from './StreamersListDesktop.scss';

interface StreamersListProps {
    streamers?: Array<ISupportedStreamer>;
}

export const StreamersListDesktop = (props: StreamersListProps) => {
    const { streamers = [] } = props;

    if (!streamers.length) {
        return null;
    }

    return (
        <>
            <div className={s.hr} />

            <ScrollableArea
                className={s.list}
                contentClassName={s.content}
            >
                {streamers.map((streamer) => (
                    <Link
                        key={streamer.streamerId}
                        className={s.item}
                        to={`/${streamer.streamerLink}`}
                        target="_blank"
                    >
                        <Avatar
                            rounded
                            className={s.avatar}
                            size={EComponentSize.SMALL}
                            source={streamer.streamerAvatar}
                        />
                        <div
                            className={s.text}
                            title={streamer.streamerName}
                        >
                            {streamer.streamerName}
                        </div>
                    </Link>
                ))}
            </ScrollableArea>
        </>
    );
};
