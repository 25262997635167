import { useLogger } from '@hyperclap/ui';
import { useState } from 'react';

import {
    DEFAULT_SEND_DELAY,
    DEFAULT_STICKER_PRICE,
    DEFAULT_STICKER_VOICING_PRICE,
    MEME_PARTY_DEFAULT_SEND_DELAY,
} from '@common';
import { useAppActions } from '@hooks';
import { ISticker } from '@typings';

import { IStickerPopupDataExtended } from '../../../../../types';

interface IUseControlsSendParams extends IStickerPopupDataExtended {
    sticker: ISticker;
    updateSticker(stickerData: ISticker): void;
    showBuyCurrencyPopup(): void;
    showAuth(): void;
}

const MEME_PARTY_DEFAULT_SEND_DELAY_SETTING_NAME = 'channel.memeParty.defaultSendDelay';
const DEFAULT_SEND_DELAY_SETTING_NAME = 'channel.stickers.defaultSendDelay';
const STICKERS_MESSAGES_ENABLED_SETTING_NAME = 'channel.stickers.enableMessages';
const VOICING_ENABLED_GLOBALLY = 'obs.voicing.enabled';

export const useControlsSend = (params: IUseControlsSendParams) => {
    const {
        sticker,
        balance: { balance },
        currentUser: { currentUser },
        streamer: { streamer },
        stickers: {
            memePartySessionState,
            sendSticker,
            sendMemeCannonSticker,
            topic,
            addStickerToFavorites,
            removeStickerFromFavorites,
            addStickerToChannel,
            removeStickerFromChannel,
            changeStickerCustomSettings,
        },
        settings: {
            system,
        },
        showBuyCurrencyPopup,
        showAuth,
        updateSticker,
    } = params;
    const logger = useLogger({ target: 'useControlsSend', showTimestamp: true });
    const [message, setMessage] = useState('');
    const [memeCannonStickersCount, setMemeCountStickersCount] = useState(5);
    const [isSoundOnly, setIsSoundOnly] = useState(false);
    const [isCannonCountDropdownOpened, setIsCannonCountDropdownOpened] = useState(false);

    const { setStickerSendResult, setSendButtonAnimationActive } = useAppActions();

    const voicingPrice = system.asBool(VOICING_ENABLED_GLOBALLY) && (streamer?.channel.isStickersMessagesTtsEnabled ?? true) && message
        ? streamer?.channel.stickerVoicingPrice || DEFAULT_STICKER_VOICING_PRICE
        : 0;

    const stickerPrice = memePartySessionState?.active
        ? voicingPrice
        : (sticker?.customSettings?.customPrice || streamer?.channel.catalogueStickerPrice || DEFAULT_STICKER_PRICE) + voicingPrice;
    const fullscreenStickerPrice = (streamer?.channel.fullscreenStickersPrice || DEFAULT_STICKER_PRICE) + voicingPrice;
    const memeCannonStickerPrice = streamer?.channel.memeCannonPrice || DEFAULT_STICKER_PRICE;
    const cooldownTime = memePartySessionState?.active
        ? streamer?.channel?.memePartySendDelay ??
            system.asInt(MEME_PARTY_DEFAULT_SEND_DELAY_SETTING_NAME) ??
            MEME_PARTY_DEFAULT_SEND_DELAY
        : streamer?.channel.sendDelay ??
            system.asInt(DEFAULT_SEND_DELAY_SETTING_NAME) ??
            DEFAULT_SEND_DELAY;
    const isMessageEnabled = system.asBool(STICKERS_MESSAGES_ENABLED_SETTING_NAME) &&
        // streamer?.channel.isPopupNickNameEnabled &&
        // streamer?.channel.isStickersMessagesEnabled &&
        streamer?.channel?.isStickersMessagesTtsEnabled &&
        !streamer?.channel.disableStickers;

    const onStickerSend = async () => {
        if (streamer && sticker) {
            if (currentUser) {
                if (balance >= stickerPrice || (memePartySessionState?.enabled && memePartySessionState?.active)) {
                    try {
                        setSendButtonAnimationActive(true);
                        const sendRes = await sendSticker({
                            toChannel: streamer.id,
                            stickerId: sticker.id,
                            isSoundOnly,
                            topic,
                            name: currentUser.name,
                            isMemePartyActive: (memePartySessionState?.enabled && memePartySessionState?.active),
                            message,
                        }).unwrap();
                        setStickerSendResult(sendRes.result);

                        return sendRes;
                    } catch (error) {
                        logger.error(error);
                    }
                } else {
                    showBuyCurrencyPopup();
                }
            } else {
                showAuth();
            }
        }
    };

    const onFullscreenSend = async () => {
        if (streamer && sticker) {
            if (currentUser) {
                if (balance >= fullscreenStickerPrice) {
                    try {
                        setSendButtonAnimationActive(true);
                        const sendRes = await sendSticker({
                            toChannel: streamer.id,
                            fullscreen: true,
                            isSoundOnly,
                            stickerId: sticker.id,
                            topic,
                            name: currentUser.name,
                            isMemePartyActive: (memePartySessionState?.enabled && memePartySessionState?.active),
                            message,
                        }).unwrap();
                        setStickerSendResult(sendRes.result);

                        return sendRes;
                    } catch (error) {
                        logger.error(error);
                    }
                } else {
                    showBuyCurrencyPopup();
                }
            } else {
                showAuth();
            }
        }
    };

    const onCannonSend = async () => {
        if (streamer && sticker) {
            if (currentUser) {
                if (balance >= memeCannonStickerPrice * memeCannonStickersCount + voicingPrice) {
                    try {
                        setSendButtonAnimationActive(true);
                        const sendRes = await sendMemeCannonSticker({
                            toChannel: streamer.id,
                            stickerId: sticker.id,
                            isSoundOnly,
                            topic,
                            count: memeCannonStickersCount,
                            name: currentUser.name,
                            isMemePartyActive: (memePartySessionState?.enabled && memePartySessionState?.active),
                            message,
                        }).unwrap();
                        setStickerSendResult(sendRes.result);

                        return sendRes;
                    } catch (error) {
                        logger.error(error);
                    }
                } else {
                    showBuyCurrencyPopup();
                }
            } else {
                showAuth();
            }
        }
    };

    const onFavoritesClick = async () => {
        let updatedSticker: ISticker;

        if (sticker.isFavorite) {
            updatedSticker = await removeStickerFromFavorites(sticker);
        } else {
            updatedSticker = await addStickerToFavorites(sticker);
        }

        updateSticker(updatedSticker);
    };

    const onChannelClick = async () => {
        let updatedSticker: ISticker;

        if (sticker.isAddedToChannel) {
            updatedSticker = await removeStickerFromChannel(sticker);
        } else {
            updatedSticker = await addStickerToChannel(sticker);
        }

        updateSticker(updatedSticker);
    };

    const onBlacklistClick = async () => {
        const {
            customPrice,
            customPriceEnabled,
            customVolume,
            customVolumeEnabled,
            disableSticker,
        } = sticker?.customSettings || {};
        const stickerCustomSettings = {
            customPrice,
            customPriceEnabled,
            customVolume,
            customVolumeEnabled,
            disableSticker: !disableSticker,
        };

        const updatedSticker = await changeStickerCustomSettings(
            sticker,
            stickerCustomSettings,
        );

        updateSticker(updatedSticker);
    };

    const onMessageChange = (value: string) => {
        setMessage(value);
    };

    const onMemeCannonStickersCountChange = (value: number) => {
        setMemeCountStickersCount(value);
        setIsCannonCountDropdownOpened(false);
    };

    const onSoundOnlyChange = (value: boolean) => setIsSoundOnly(value);

    const onCannonCountClick = () => setIsCannonCountDropdownOpened(true);

    const onCannonCountOutsideClick = () => setIsCannonCountDropdownOpened(false);

    return {
        message,
        memeCannonStickersCount,
        isSoundOnly,
        isCannonCountDropdownOpened,
        isMessageEnabled,
        price: {
            sticker: stickerPrice,
            fullscreen: fullscreenStickerPrice,
            cannon: memeCannonStickerPrice * memeCannonStickersCount + voicingPrice,
        },
        cooldownTime,
        onStickerSend,
        onFullscreenSend,
        onCannonSend,
        onMessageChange,
        onMemeCannonStickersCountChange,
        onFavoritesClick,
        onChannelClick,
        onBlacklistClick,
        onSoundOnlyChange,
        onCannonCountClick,
        onCannonCountOutsideClick,
    };
};
