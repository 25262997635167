import { useEffect, useState } from 'react';

import { useApi, useCurrentUser } from '@hooks';

export const useVerification = () => {
    const {
        verification: {
            useLazyGetVerificationUrlQuery,
            useLazyResetVerificationQuery,
        },
    } = useApi();

    const { currentUser } = useCurrentUser();

    const [verificationUrl, setVerificationUrl] = useState<string | null>(null);
    const [getUrl] = useLazyGetVerificationUrlQuery();
    const [reset] = useLazyResetVerificationQuery();

    const loadVerificationUrl = async () => {
        setVerificationUrl((await getUrl().unwrap())?.url);
    };

    const resetVerification = async () => {
        reset();
    };

    useEffect(() => {
        if (currentUser && !currentUser.isUserVerified && !verificationUrl) {
            void loadVerificationUrl();
        }
    }, [currentUser]);

    return {
        verificationUrl,
        loadVerificationUrl,
        resetVerification,
    };
};
