import {
    Column,
    Row,
    useClickOutside,
    useModals,
    EColumnJustification,
    ThemeContext,
    ETheme,
} from '@hyperclap/ui';
import React, { useContext, useRef, useState } from 'react';

import { IconBellLine, IconStar4Line } from '@assets/images/svg';
import { Avatar } from '@components';
import { TApp } from '@hooks';
import { Login, Logo } from '@memealerts/views';
import { BalanceButton, EarnBonusButton, HeaderButton, LogoKind, ProfileMenu, WelcomeBonusPopup } from '@views';
import { UserVerificationPopup } from '@views/modals/UserVerificationPopup';

import s from './Header.scss';

interface IHeaderProps extends TApp {
    showStreamerAvatar?: boolean;
    headerRef?: React.RefObject<HTMLElement>;
}

export const Header = (props: IHeaderProps) => {
    const {
        balance: {
            balanceLoaded,
            earnWelcomeBonus,
            reloadBalance,
            welcomeBonusEarned,
        },
        currentUser: {
            currentUser,
            state: currentUserState,
            logout,
        },
        deviceInfo: {
            isDesktopLayoutRecommended,
            isMobileLayoutRecommended,
        },
        navigation: {
            goToStickerCreation,
            goToStreamerPage,
            openEventsTab,
        },
        streamer: {
            streamer,
            state: {
                isLoading: isStreamerLoading,
            },
        },
        settings: {
            system,
        },
        translation: {
            t,
        },
        showStreamerAvatar,
    } = props;

    const { theme } = useContext(ThemeContext);

    const { showModal } = useModals();

    const avatarRef = useRef<HTMLDivElement>(null);

    useClickOutside({
        controlledElementRef: avatarRef,
        handler: () => setIsMenuVisible(false),
    });

    const [isMenuVisible, setIsMenuVisible] = useState(false);

    const onAuthClick = async () => {
        showModal({
            content: <Login returnUrl={location.pathname} {...props}/>,
            options: {
                bodyClassName: s.loginModalBody,
            },
        });
    };

    const onEarnWelcomeBonusClick = async () => {
        if (!currentUser) {
            showModal({
                content: <Login returnUrl={location.pathname} {...props}/>,
                options: {
                    bodyClassName: s.loginModalBody,
                },
            });
        }

        if (currentUser && streamer) {
            if (streamer.channel.welcomeBonusVerificationEnabled && !currentUser.isUserVerified) {
                showModal(<UserVerificationPopup
                    currentUser={currentUser}
                    isMobileLayoutRecommended={isMobileLayoutRecommended}
                    reloadBalance={reloadBalance}
                />);
            } else {
                const result = await earnWelcomeBonus(streamer.id).unwrap();

                if (result.welcomeBonusEarned) {
                    reloadBalance();
                    showModal(<WelcomeBonusPopup streamer={streamer} />);
                }
            }
        }
    };

    const logoSidePadding = isMobileLayoutRecommended ? 15 : 24;

    return (
        <Row grow className={s.streamerAreaHeader}>
            {/* Logo, Only for desktop version */}
            { isDesktopLayoutRecommended &&
                <Column width={176} padding={{ left: logoSidePadding }} justification={EColumnJustification.CENTER}>
                    <Logo
                        width={96}
                        kind={theme === ETheme.LIGHT ? LogoKind.LIGHT : LogoKind.DARK}
                        onClick={() => goToStreamerPage(streamer?.channel.link)}
                    />
                </Column>
            }

            { isMobileLayoutRecommended && showStreamerAvatar &&
                <Column padding={{ left: 20 }}>
                    <Avatar
                        source={streamer?.channel.avatarUrl}
                        preventShowDefault={isStreamerLoading || streamer?.channel.avatarUrl !== ''}
                        className={s.streamerAvatar}
                        onClick={() => goToStreamerPage(streamer?.channel.link)}
                    />
                </Column>
            }

            {/* Expander*/}
            <Column grow/>

            {/* If current user loaded*/}
            { currentUser
                ? <Row>
                    {/* If desktop version is shown then show "Create" button and "Events" button*/}
                    { !isMobileLayoutRecommended &&
                        <Row>
                            <Column margin={{ right: 50 }}>
                                <HeaderButton
                                    caption={t('common.create')}
                                    icon={<IconStar4Line/>}
                                    onClick={goToStickerCreation}
                                />
                            </Column>
                            <Column margin={{ left: 20, right: 20 }}>
                                <HeaderButton
                                    circle
                                    icon={<IconBellLine/>}
                                    onClick={openEventsTab}
                                />
                            </Column>
                        </Row>
                    }

                    {/* If welcomeBonus is not earned then show earn bonus button else show balance button */}
                    <Column margin={{ right: 20 }}>
                        { streamer && balanceLoaded && !streamer.channel.disableStickers
                            ? welcomeBonusEarned || !streamer?.channel.welcomeBonusEnabled || !system.asBool('bonuses.enableWelcomeBonus')
                                ? <BalanceButton {...props}/>
                                : <EarnBonusButton onClick={onEarnWelcomeBonusClick}/>
                            : null
                        }
                    </Column>

                    {/* User profile menu with avatar*/}
                    <Column margin={{ right: logoSidePadding }} className={s.profileMenuContainer}>
                        <Avatar
                            source={currentUser?.avatar}
                            ref={avatarRef}
                            rounded
                            isUserAvatar
                            onClick={() => setIsMenuVisible(!isMenuVisible)}
                        />
                        { isMenuVisible && currentUser &&
                            <ProfileMenu className={s.profileMenu} currentUser={currentUser} onLogout={logout}/>
                        }
                    </Column>
                </Row>
                : isMobileLayoutRecommended
                    ? <Column padding={{ right: logoSidePadding }}>
                        <EarnBonusButton onClick={onEarnWelcomeBonusClick} />
                    </Column>
                    : (!currentUserState.isLoading &&
                        <Row>
                            <Column margin={{ left: 15, right: logoSidePadding }}>
                                { streamer &&
                                    <EarnBonusButton onClick={onEarnWelcomeBonusClick} />
                                }
                            </Column>
                            <Column margin={{ right: 20 }}>
                                <HeaderButton
                                    caption={t('common.login')}
                                    onClick={onAuthClick}
                                />
                            </Column>
                        </Row>
                    )
            }
        </Row>
    );
};
