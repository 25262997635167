import React from 'react';

import { OBS_TOKEN_NAME } from '@common';
import { useAppActions, useAuth } from '@hooks';
import { LoaderPage } from '@memealerts/views';
import { EAuthMode } from '@typings';

interface IAuthRequiredProps {
    allowUseObsToken?: boolean;
}

export const AuthRequired = (props: React.PropsWithChildren<IAuthRequiredProps>) => {
    const { allowUseObsToken, children } = props;
    const { authToken, redirectUnauthorizedTo, isAuthInProgress } = useAuth();
    const { updateMode } = useAppActions();

    const obsToken = localStorage.getItem(OBS_TOKEN_NAME);

    if (!authToken && allowUseObsToken && obsToken) {
        updateMode(EAuthMode.OBS);
    }

    return isAuthInProgress
        ? <LoaderPage/>
        : authToken
            ? children
            : allowUseObsToken
                ? children
                : redirectUnauthorizedTo;
};
