import { useEffect, useRef } from 'react';

interface IOneShotParams {
    fn: () => void;
}

export const useOneShot = (params: IOneShotParams) => {
    const { fn } = params;

    const isDone = useRef<boolean>(false);

    useEffect(() => {
        if (!isDone.current) {
            isDone.current = true;
            fn();
        }
    }, []);
};
