import {
    Drawer,
    useClickOutside,
    useModals,
} from '@hyperclap/ui';
import React, { useRef, useState } from 'react';

import { MobileAvatar } from '@components/avatars/MobileAvatar';
import { TApp } from '@hooks';
import { Login } from '@memealerts/views';
import { BalanceButton, EarnBonusButton, ProfileMenuMobile, WelcomeBonusPopup } from '@views';
import { UserVerificationPopup } from '@views/modals/UserVerificationPopup';

import s from './Header.scss';

interface IHeaderProps extends TApp {
    headerRef?: React.RefObject<HTMLElement>;
}

export const Header = (props: IHeaderProps) => {
    const {
        balance: {
            balanceLoaded,
            earnWelcomeBonus,
            reloadBalance,
            welcomeBonusEarned,
        },
        currentUser: {
            currentUser,
            state,
            logout,
        },
        streamer: {
            streamer,
        },
        settings: {
            system,
        },
    } = props;

    const { showModal } = useModals();

    const avatarRef = useRef<HTMLDivElement>(null);

    useClickOutside({
        controlledElementRef: avatarRef,
        handler: () => setIsMenuVisible(false),
    });

    const [isMenuVisible, setIsMenuVisible] = useState(false);

    const onEarnWelcomeBonusClick = async () => {
        if (!currentUser) {
            showModal({
                content: <Login returnUrl={location.pathname} {...props}/>,
                options: {
                    bodyClassName: s.loginModalBody,
                },
            });
        }

        if (currentUser && streamer) {
            if (streamer.channel.welcomeBonusVerificationEnabled && !currentUser.isUserVerified) {
                showModal(<UserVerificationPopup
                    currentUser={currentUser}
                    isMobileLayoutRecommended
                    reloadBalance={reloadBalance}
                />);
            } else {
                const result = await earnWelcomeBonus(streamer.id).unwrap();

                if (result.welcomeBonusEarned) {
                    reloadBalance();
                    showModal(<WelcomeBonusPopup streamer={streamer} />);
                }
            }
        }
    };

    return (
        <div className={s.header}>
            {
                state.isLoading
                    ? null
                    : currentUser
                        ? <>
                            {streamer && balanceLoaded && !streamer.channel.disableStickers
                                ? welcomeBonusEarned ||
                                    !streamer?.channel.welcomeBonusEnabled ||
                                    !system.asBool('bonuses.enableWelcomeBonus')
                                    ? <BalanceButton {...props} />
                                    : <EarnBonusButton onClick={onEarnWelcomeBonusClick} />
                                : null
                            }

                            <MobileAvatar
                                loading={state.isLoading}
                                src={currentUser?.avatar}
                                onClick={() => setIsMenuVisible(true)}
                            />

                            <Drawer
                                show={isMenuVisible && !!currentUser}
                                onClose={() => setIsMenuVisible(false)}
                            >
                                <ProfileMenuMobile
                                    onLogout={logout}
                                />
                            </Drawer>
                        </>
                        : <EarnBonusButton onClick={onEarnWelcomeBonusClick} />
            }
        </div>
    );
};
