import { ThemeContext } from '@hyperclap/ui';
import React, { useContext, useLayoutEffect } from 'react';

import { TApp } from '@hooks';
import { Footer } from '@memealerts/views';
import { MobilePage, DesktopPage } from '@views';

import { Header, MobileHeader, LeftSideMenu } from './components';


interface IPersonalAreaPage extends TApp {
    noRightSidebar?: boolean;
}

export const PersonalAreaPage = (props: React.PropsWithChildren<IPersonalAreaPage>) => {
    const { forceUpdateTheme } = useContext(ThemeContext);

    const {
        children,
        deviceInfo: {
            isMobileLayoutRecommended,
        },
        noRightSidebar,
        personalArea: {
            streamers: {
                setSearchQuery,
            },
        },
    } = props;

    useLayoutEffect(() => {
        forceUpdateTheme();
    }, []);

    const onSteamersSearch = (search: string): void => {
        setSearchQuery(search);
    };

    return (
        isMobileLayoutRecommended
            ? <MobilePage
                header={
                    <MobileHeader
                        onSteamersSearch={onSteamersSearch}
                        {...props}
                    />
                }
            >
                {children}
            </MobilePage>
            : <DesktopPage
                stickyHeader
                header={<Header {...props}/>}
                footer={<Footer {...props} hideThemeSwitch/>}
                leftSidebar={<LeftSideMenu {...props}/>}
                hideRightSidebar={noRightSidebar}
            >
                {children}
            </DesktopPage>
    );
};
