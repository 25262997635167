import React from 'react';

import { BackgroundMode } from '@typings';

import s from './MobilePage.scss';

interface IMobilePageProps {
    /** Optional header element */
    header?: React.ReactElement;

    /** Page background picture */
    pageBackgroundUrl?: string;

    /** Page background picture mode (0 - cover, 1 - tile) */
    pageBackgroundMode?: BackgroundMode;

    /** Prevent scroll content under popups */
    preventScroll?: boolean;
}

export const MobilePage = (props: React.PropsWithChildren<IMobilePageProps>) => {
    const {
        children,
        header,
        pageBackgroundMode = BackgroundMode.COVER,
        pageBackgroundUrl,
    } = props;

    const pageStyles = pageBackgroundUrl
        ? {
            backgroundImage: `url(${pageBackgroundUrl})`,
            backgroundPosition: pageBackgroundMode ? '0 0' : 'center',
            backgroundSize: pageBackgroundMode ? 'center center' : 'cover',
        }
        : {};

    return (
        <div className={s.page}>
            {header}

            <main className={s.content} style={pageStyles}>
                {children}
            </main>
        </div>
    );
};
