import { Loader } from '@hyperclap/ui';
import React, { useEffect } from 'react';

import { useAppActions, useAppContext, useElementOnScreen } from '@hooks';
import { useAppSelector } from '@hooks/store/AppSelector';
import { ISticker, StickerSendTopic } from '@typings';
import { Empty, MobileStickersGrid } from '@views';

import s from './LastSentPage.scss';


export const LastSentPage = () => {
    const app = useAppContext();
    const {
        currentUser: {
            currentUser,
        },
        stickers: {
            setStickerToSend,
        },
        streamer: {
            streamer,
        },
        streamerArea: {
            stickers: {
                lastSent: {
                    isFetching,
                    isLoadingAllowed,
                    stickers,
                    loadNextPage,
                },
            },
        },
        translation: {
            t,
        },
    } = app;

    const playSoundOnStickerHover = useAppSelector((state) => state.app.playSoundOnStickerHover);
    const { switchPlaySoundOnStickerHover } = useAppActions();

    const { elementRef: watchdogRef, isOnScreen: needToLoadPage } = useElementOnScreen<HTMLDivElement>({ threshold: 0 });

    const onStickerClick = (sticker: ISticker) => {
        if (streamer) {
            setStickerToSend(sticker, streamer, StickerSendTopic.LAST);
        }
    };

    useEffect(() => {
        if (needToLoadPage) {
            void loadNextPage();
        }
    }, [needToLoadPage]);

    return (
        <div className={s.lastSent}>
            <div className={s.lastSentCaption}>{t('streamer-area.my-sent')}</div>
            {stickers.length > 0
                ? <MobileStickersGrid
                    stickers={stickers}
                    stickersSoundEnabled={playSoundOnStickerHover}
                    defaultSoundVolume={currentUser?.channel?.soundVolume}
                    onStickerClick={onStickerClick}
                    onSwitchSound={switchPlaySoundOnStickerHover}
                />
                : isFetching ? <Loader className={s.loader}/> : <Empty text={'Здесь еще нет стикеров'} />
            }

            {isLoadingAllowed &&
                <div className={s.nextPageWatchdog}>
                    <div className={s.nextPageWatchdogMarker} ref={watchdogRef}/>
                </div>
            }
        </div>
    );
};
