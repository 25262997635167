import { useLogger } from '@hyperclap/ui';
import cn from 'classnames';
// eslint-disable-next-line import/no-unresolved
import config from 'config';
import React, { useEffect, useRef, useState } from 'react';

import { useSmoothVolumeControl } from '@hooks';
import { DEFAULT_GEOMETRY, IGeometry, ISticker, StickerSize } from '@typings';

import s from './StickerPlayer.scss';

const MUTED_VOLUME_PERCENT = 10;

interface StickerPlayerProps {
    uid?: string;
    sticker: ISticker;
    geometry?: IGeometry;
    size?: string;
    volume?: number;
    volumeFadeEffectDuration?: number;
    muteVolume?: boolean;
    hidden?: boolean;
    fullscreen?: boolean;
    useAnimation?: boolean;
    pageBackgroundUrl?: string,
    pageBackgroundMode?: number,
    isCopyrightFreeOnly?: boolean;
    onPlayEnd?: () => void;
}

export const StickerPlayer = (props: StickerPlayerProps) => {
    const logger = useLogger({ target: `StickerPlayer - ${props.sticker.name}`, showTimestamp: true });
    const {
        sticker: {
            stickerUrl,
            stickerPreview,
            customSettings,
            videoData,
        },
        geometry: {
            x,
            y,
            rotation,
        } = { ...DEFAULT_GEOMETRY },
        size = StickerSize.MEDIUM,
        volume = 100,
        volumeFadeEffectDuration = 700,
        muteVolume = false,
        hidden = false,
        useAnimation = false,
        fullscreen = false,
        onPlayEnd,
    } = props;

    const {
        fade,
    } = useSmoothVolumeControl();

    const { trace } = useLogger({ target: 'StickerPlayer' });

    const [isStickerPreviewLoaded, setIsStickerPreviewLoaded] = useState(false);

    const fullscreenStyles = {
        backgroundImage: `url(${stickerPreview})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        opacity: isStickerPreviewLoaded ? '1' : '0',
    };

    const styles = {
        top: y,
        left: x,
        transform: `rotate(${rotation}deg)`,
    };

    const container = useRef<HTMLVideoElement>(null);

    const [videoStyles, setVideoStyles] = useState({ });
    const [fadeOut, setFadeOut] = useState(false);

    const calcVideoStyles = () => { // TODO: Try to calc it outside of StickerPlayer
        const sizeName = size ?? StickerSize.MEDIUM;
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;
        const videoWidth = container.current!.offsetWidth;
        const videoHeight = container.current!.offsetHeight;
        const videoStyles: Record<string, unknown> = { opacity: 1 };

        const viewportDiagonal = Math.sqrt(Math.pow(viewportWidth, 2) + Math.pow(viewportHeight, 2));
        const stickerSizePercent = config?.obs.stickerSize[sizeName] ?? 25;

        trace(`Size: ${sizeName}, Percent: ${stickerSizePercent}%`);

        if (fullscreen && videoData) {
            videoStyles.height = viewportHeight;
            videoStyles.width = viewportHeight * (videoWidth / videoHeight);
        } else {
            if (videoWidth >= videoHeight) {
                const videoWidth = stickerSizePercent * viewportDiagonal / 100 + 'px';
                const videoHeight = 'auto';
                trace(`Calculated video dimensions: Width = ${videoWidth}, Height = ${videoHeight}`);
                videoStyles.width = videoWidth;
                videoStyles.height = videoHeight;
                videoStyles.borderRadius = stickerSizePercent * viewportDiagonal / 100 * 0.05;
            } else {
                trace(`Video width < Video height, calculating video size using ${stickerSizePercent}% of the height`);
                const videoWidth = 'auto';
                const videoHeight = stickerSizePercent * viewportDiagonal / 100 + 'px';
                trace(`Calculated video dimensions: Width = ${videoWidth}, Height = ${videoHeight}`);
                videoStyles.width = videoWidth;
                videoStyles.height = videoHeight;
                videoStyles.borderRadius = stickerSizePercent * viewportDiagonal / 100 * 0.05;
            }
        }

        return videoStyles;
    };

    const minStickerPlayingTimeSec: number = config?.obs?.minStickerPlayingTimeSec ?? 3;

    const onLoaded = (e: React.SyntheticEvent<HTMLVideoElement>) => {
        const videoDuration = e.currentTarget.duration || minStickerPlayingTimeSec;

        if (videoDuration && onPlayEnd) {
            const duration = videoDuration < minStickerPlayingTimeSec ? minStickerPlayingTimeSec : videoDuration;
            setTimeout(() => onPlayEnd(), duration * 1000);
            setTimeout(() => setFadeOut(true), duration * 1000 - 500);
        }

        if (container.current) {
            setVideoStyles(calcVideoStyles());
        }

        if (fullscreen && stickerPreview) {
            const img = new Image();

            img.onload = () => setIsStickerPreviewLoaded(true);
            img.src = stickerPreview;
        }
    };

    const calculateVolume = () => {
        return customSettings?.customVolumeEnabled && customSettings?.customVolume !== undefined
            ? customSettings?.customVolume
            : volume;
    };

    useEffect(() => {
        if (container.current) {
            const vol = calculateVolume();
            logger.debug(`Sticker sound volume: ${vol}`);
            container.current.volume = vol / 100;
        }
    }, [volume]);

    useEffect(() => {
        if (container.current) {
            const vol = muteVolume ? MUTED_VOLUME_PERCENT : calculateVolume();
            fade(container.current, vol, volumeFadeEffectDuration);
        }
    }, [muteVolume]);

    return (
        <div
            className={cn(
                s.stickerPlayer,
                {
                    [s.stickerPlayerHidden]: hidden,
                    [s.stickerPlayerFullscreen]: fullscreen,
                    [s.stickerPlayerAnimated]: useAnimation && !hidden,
                    [s.stickerPlayerAnimatedFadeOut]: useAnimation && fadeOut && !hidden,
                },
            )}
            style={styles}
        >
            {fullscreen && (
                <div className={cn(s.stickerPlayerFullscreenBg)} style={fullscreenStyles}/>
            )}
            <video
                ref={container}
                className={cn(s.video, { [s.videoWithBorder]: !videoData?.isTransparent && !fullscreen })}
                autoPlay={true}
                controls={false}
                src={stickerUrl}
                style={{ ...videoStyles }}
                onPlay={(e) => onLoaded(e)}
            />
        </div>
    );
};
