import { useEffect, useState } from 'react';
import { v4 } from 'uuid';

import { POPUP_STICKERS_DURATION } from '@common';
import { useList } from '@hooks';
import { IVoiceDescriptor } from '@typings';

export interface IPopupNickName extends IBasePopupInfo {
    stickerName: string;
    userAlias?: string;
    userAvatar?: string;
    fullscreen?: boolean;
    message?: string;
    isVoiceUsed?: boolean;
    userVoice?: Pick<IVoiceDescriptor, 'voice' | 'provider'>;
}

export interface IPopupMemeCannon extends IBasePopupInfo {
    stickerName: string;
    userAlias?: string;
    userAvatar?: string;
    count: number;
    message?: string;
    isVoiceUsed?: boolean;
    userVoice?: Pick<IVoiceDescriptor, 'voice' | 'provider'>;
}

export interface IPopupNewSale extends IBasePopupInfo {
    userName?: string;
    packDescription: string;
    userAvatar?: string;
}

export interface IPopupNewSupporter extends IBasePopupInfo {
    userName?: string;
    userAvatar?: string;
}

export interface IWelcomeBonusEarned extends IBasePopupInfo {
    userName?: string;
    bonusValue: number;
    userAvatar?: string;
}

export interface IBasePopupInfo {
    uid: string;
    type: EPopupTypes;
}

export enum EPopupTypes {
    NICK_NAME,
    NEW_SALE,
    WELCOME_BONUS_EARNED,
    MEME_CANNON_STICKER_SENT,
    NEW_SUPPORTER,
}

export type IPopupInfo = IPopupNickName | IPopupNewSale | IWelcomeBonusEarned | IPopupMemeCannon | IPopupNewSupporter;

export const usePopupStickersList = () => {
    const [timer, setTimer] = useState<NodeJS.Timeout>();
    const [popupInfo, setPopupInfo] = useState<IPopupInfo>();

    const {
        list,
        addToList: add,
        removeFromList: remove,
    } = useList<IPopupNickName>();

    const addToList = (info: IPopupInfo) => {
        const uid = v4();
        const currentSticker = { ...info, uid } as IPopupNickName;
        add(currentSticker);
    };

    const startProcessing = (currentSticker: IPopupNickName) => {
        setPopupInfo(currentSticker);
        setTimer(setTimeout(() => {
            setPopupInfo(undefined);
            setTimer(undefined);
            removeFromList(currentSticker.uid);
        }, POPUP_STICKERS_DURATION));
    };

    const removeFromList = (uid: string) => {
        remove(uid);
    };

    useEffect(() => {
        if (!timer && list.length > 0) {
            startProcessing(list[0]);
        }
    }, [list]);

    return {
        popupInfo,
        addToList,
    };
};
