import { EComponentSize, Loader } from '@hyperclap/ui';
import { t } from 'i18next';
import React from 'react';
import { Link } from 'react-router-dom';

import { IconSmileBeamSolid, IconDesktopSolid } from '@assets/images/svg';
import { Avatar } from '@components';
import { IStreamer } from '@typings';
import { Empty } from '@views';

import s from './StreamersListMobile.scss';

interface StreamersListMobileProps {
    streamers?: IStreamer[];
    openInNewTab?: boolean;
}

export const StreamersListMobile = (props: StreamersListMobileProps) => {
    const {
        streamers,
        openInNewTab,
    } = props;

    if (!streamers) {
        return (
            <Loader
                containerClassName={s.loading}
                size={EComponentSize.MEDIUM}
            />
        );
    }

    if (!streamers.length) {
        return (
            <div>
                <Empty
                    icon={<IconSmileBeamSolid />}
                    iconSize={42}
                    margin={{ top: 65 }}
                    text={
                        <div className={s.empty}>
                            {t('streamers.emptyText1')}
                        </div>
                    }
                />
                <br />
                <Empty
                    icon={<IconDesktopSolid />}
                    iconSize={42}
                    margin={{ top: 40 }}
                    text={
                        <div className={s.empty}>
                            {t('streamers.emptyText2')}
                        </div>
                    }
                />

            </div>
        );
    }

    const target = openInNewTab ? '_blank' : '_self';

    return (
        <div className={s.list}>
            {streamers.map((streamer) => (
                <Link
                    key={streamer.id}
                    className={s.item}
                    to={`/${streamer.link}`}
                    target={target}
                >
                    <Avatar
                        className={s.streamerAvatar}
                        size={EComponentSize.MEDIUM}
                        source={streamer.avatar}
                        rounded
                    />
                    <div
                        className={s.streamerText}
                        title={streamer.name}
                    >
                        {streamer.name}
                    </div>
                </Link>
            ))}
        </div>
    );
};
