import React from 'react';

import { useApp } from '@hooks';

import { StreamerAreaDesktop } from './StreamerAreaDesktop';
import { StreamerAreaMobile } from './StreamerAreaMobile';


export const StreamerArea = () => {
    const app = useApp();

    if (app.deviceInfo.isMobileLayoutRecommended) {
        return (<StreamerAreaMobile />);
    }

    return (<StreamerAreaDesktop />);
};
