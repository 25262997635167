/** Default value of newbie action */
export const DEFAULT_NEWBIE_ACTION = 3;

/** Default value of welcome bonus */
export const DEFAULT_WELCOME_BONUS = 5;

/** Default send delay between two stickers */
export const DEFAULT_SEND_DELAY = 1000;

/** Default sound volume which is used to play sticker */
export const DEFAULT_STICKER_PRICE = 10;

/** Default sticker voicing price */
export const DEFAULT_STICKER_VOICING_PRICE = 2;

/** Default sound volume which is used to play sticker */
export const DEFAULT_SOUND_VOLUME = 90;

/** Sticker search query parameter name */
export const STICKER_SEARCH_QUERY_NAME = 'query';

/** Sticker search query parameter name */
export const STREAMER_SEARCH_QUERY_NAME = 'search';
