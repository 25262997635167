import { useCurrentUser } from '@hooks';

export const useAmouranthEmptyState = () => {
    const { currentUser } = useCurrentUser();
    const isAmouranth = [
        '66ab7f80d610e6b5640f45cf',
        '67c38aa5a80054ea0d555bea',
        '66262d5592bcc2dd29ad8f37',
    ].includes(String(currentUser?.id));

    return {
        isAmouranth,
    };
};
