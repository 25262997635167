import React from 'react';

import { AmouranthEmptyState, useAmouranthEmptyState } from '@views/fragments/AmouranthEmptyState';

export const Mobile = () => {
    const { isAmouranth } = useAmouranthEmptyState();

    return isAmouranth
        ? <AmouranthEmptyState/>
        : null;
};
