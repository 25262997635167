// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StickersGrid__grid--AiBTI {
  display: grid;
  grid-template-columns: repeat(auto-fill, 180px);
  grid-auto-rows: 1px;
  justify-content: center;
  column-gap: 4px;
}

.StickersGrid__item--oGIBn {
  margin-bottom: 4px;
  break-inside: avoid;
  grid-row: span var(--span, 1);
}`, "",{"version":3,"sources":["webpack://./src/base/views/fragments/StickersGrid/StickersGrid.scss"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,+CAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;AAAJ;;AAGA;EACI,kBAAA;EACA,mBAAA;EACA,6BAAA;AAAJ","sourcesContent":[".grid {\n    // (1040 -4*3 - 28) / 4\n    display: grid;\n    grid-template-columns: repeat(auto-fill, 180px);\n    grid-auto-rows: 1px;\n    justify-content: center;\n    column-gap: 4px;\n  }\n\n.item {\n    margin-bottom: 4px;\n    break-inside: avoid;\n    grid-row: span var(--span, 1);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": `StickersGrid__grid--AiBTI`,
	"item": `StickersGrid__item--oGIBn`
};
export default ___CSS_LOADER_EXPORT___;
