import { Button, Row } from '@hyperclap/ui';
import { noop } from '@hyperclap/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconCheckLine, IconCloseLine } from '@assets/images/svg';
import { IStickerRelatedEvent } from '@typings';

import s from './PreModerationControls.scss';

interface IPreModerationControlsProps {
    event: IStickerRelatedEvent;
    onApprove?: (event: IStickerRelatedEvent) => void;
    onDecline?: (event: IStickerRelatedEvent) => void;
}

export const PreModerationControls = (props: IPreModerationControlsProps) => {
    const {
        event,
        onApprove = noop,
        onDecline = noop,
    } = props;

    const { t } = useTranslation();

    return (
        <Row columnGap={5}>
            <Button
                icon={<IconCheckLine/>}
                caption={t('common.approve')}
                className={s.button}
                iconClassName={s.buttonIcon}
                onClick={() => onApprove(event)}
            />
            <Button
                icon={<IconCloseLine/>}
                // caption={t('common.decline')}
                className={s.button}
                iconClassName={s.buttonIcon}
                onClick={() => onDecline(event)}
            />
        </Row>
    );
};
