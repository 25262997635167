import { t } from 'i18next';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { IconBombSolid, IconDesktopSolid, IconExclamationSolid, IconSmileGrinSolid } from '@assets/images/svg';
import { NotificationType } from '@contexts';
import { useNotifications } from '@hooks';
import { useSupporter } from '@hooks/app/personal-area/Supporter';
import { EventKind, IMemeCannonStickerSentEvent, IStickerRelatedEvent, IStickerSentEvent, IUser } from '@typings';
import { PreModerationControls } from '@views/fragments/EventItem/PreModerationControls';
import { BonusPopup } from '@views/pages/PersonalArea/components/Supporter/components';

import { Controls } from './Controls';
import { BaseEvent, IEventItemProps } from './EventBase';
import s from './EventBase.scss';

interface IStickerSentProps extends IEventItemProps<IStickerSentEvent | IMemeCannonStickerSentEvent> {
    currentUser?: IUser;
    onReplayClick(event: IStickerSentEvent): void;
    onToggleDisableStateClick(event: IStickerSentEvent): void;
    onToggleMuteStateClick(event: IStickerSentEvent): void;
    onApproveClick(event: IStickerRelatedEvent): void;
    onDeclineClick(event: IStickerRelatedEvent): void;
}

export const EventStickerSent = (props: IStickerSentProps) => {
    const {
        currentUser,
        event,
        onReplayClick,
        onToggleDisableStateClick,
        onToggleMuteStateClick,
        onApproveClick,
        onDeclineClick,
        animated,
    } = props;

    const to = `/supporters/${event.userId}`;
    const isMemeCannon = event.kind === EventKind.MEME_CANNON_STICKER_SENT;
    const isFullscreen = 'fullscreen' in event && event.fullscreen;

    const {
        supporter,
        sendBonus,
        setBonusSize,
    } = useSupporter({ supporterId: event.userId ?? '' });

    const { notify } = useNotifications();

    const [showBonusPopup, setShowBonusPopup] = useState(false);

    const onOpenBonusPopup = () => requestAnimationFrame(() => setShowBonusPopup(true));

    const onCloseBonusPopup = () => {
        console.log('onClose', showBonusPopup, false);
        setShowBonusPopup(false);
    };

    const onSendBonusClick = async () => {
        sendBonus(supporter!.supporterId, currentUser!.id);
        setShowBonusPopup(false);
        notify('Награда успешно отправлена!', NotificationType.SUCCESS); // TODO: translation
    };

    const isPreModerationNeeded = event.isPreModerationNeeded;

    const icon = isPreModerationNeeded
        ? <IconExclamationSolid/>
        : isMemeCannon
            ? <IconBombSolid />
            : isFullscreen ? <IconDesktopSolid /> : <IconSmileGrinSolid />;

    const action = isMemeCannon
        ? t('events.started') + `x${event.count}`
        : isFullscreen ? t('events.sent-fullscreen') : t('events.sent');

    return (
        <BaseEvent
            animated={animated}
            event={event}
            color={isPreModerationNeeded ? s.red : s.fuchsia}
            icon={icon}
            user={<Link to={to}>{event.userAlias || t('common.anonymous')}</Link>}
            action={action}
            subject={event.stickerName ?? t('events.without-name')}
            message={event.message}
            popup={currentUser && showBonusPopup && <BonusPopup
                price={currentUser.channel.catalogueStickerPrice}
                slim
                onBonusSizeChange={setBonusSize}
                onClose={showBonusPopup ? onCloseBonusPopup : undefined}
                onSendBonusClick={onSendBonusClick}
            />}
        >
            { isPreModerationNeeded
                ? <PreModerationControls
                    event={event}
                    onApprove={onApproveClick}
                    onDecline={onDeclineClick}
                />
                : <Controls
                    event={event}
                    onShowBonusPopup={showBonusPopup ? undefined : onOpenBonusPopup}
                    onReplayClick={onReplayClick}
                    onToggleDisableStateClick={onToggleDisableStateClick}
                    onToggleMuteStateClick={onToggleMuteStateClick}
                />
            }
        </BaseEvent>
    );
};
