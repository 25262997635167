// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MobileStickersGrid__grid--Tq33k {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-auto-rows: 1px;
  justify-content: center;
  column-gap: 4px;
}

.MobileStickersGrid__item--l2x9n {
  margin-bottom: 10px;
  break-inside: avoid;
  grid-row: span var(--span, 50);
}`, "",{"version":3,"sources":["webpack://./src/base/views/fragments/MobileStickersGrid/MobileStickersGrid.scss"],"names":[],"mappings":"AAYA;EACI,aAAA;EACA,qCAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;AAXJ;;AAcA;EACI,mBAAA;EACA,mBAAA;EACA,8BAAA;AAXJ","sourcesContent":["// .grid {\n//     // (1040 -4*3 - 28) / 4\n//     display: flex;\n//     flex-direction: column;\n//     align-items: stretch;\n//     // row-gap: 8px;\n// }\n\n// .sticker {\n//     padding-bottom: 8px;\n// }\n\n.grid {\n    display: grid;\n    grid-template-columns: repeat(2, 50%);\n    grid-auto-rows: 1px;\n    justify-content: center;\n    column-gap: 4px;\n  }\n\n.item {\n    margin-bottom: 10px;\n    break-inside: avoid;\n    grid-row: span var(--span, 50);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": `MobileStickersGrid__grid--Tq33k`,
	"item": `MobileStickersGrid__item--l2x9n`
};
export default ___CSS_LOADER_EXPORT___;
